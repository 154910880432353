<template>
  <div class="alarm-action-comp">
    <div class="detail-line">
      <span class="select-label"> 告警条件 </span>
      <span class="split-span"> 持续时长达到 </span>
      <el-input-number
        :disabled="currentState === 'view'"
        class="number-input"
        v-model="form.time"
        :controls="false"
        :min="0"
      ></el-input-number>
      <span class="split-span"> 分钟，并且连续触发达到 </span>
      <el-input-number
        :disabled="currentState === 'view'"
        class="number-input"
        v-model="form.times"
        :controls="false"
        :min="1"
      ></el-input-number>
      <span class="split-span"> 次的告警，执行以下设置：</span>
    </div>
    <el-form :model="form" ref="actionForm">
      <el-row :gutter="48" class="form-line">
        <el-col :span="12" class="params-line">
          <div class="params-title">告警状态</div>
          <div class="params-detail">
            <el-select
              :disabled="currentState === 'view'"
              v-model="form.alarmStatus"
              class="w330"
            >
              <el-option
                :label="$t('vlink.rule.alarm')"
                value="alarm"
              ></el-option>
              <el-option
                :label="$t('vlink.rule.normal')"
                value="normal"
              ></el-option>
            </el-select>
          </div>
        </el-col>

        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'normal'"
        >
          <div class="params-title">关联告警</div>
          <div class="params-detail">
            <el-form-item
              prop="relatedAlarm"
              :rules="
                form.alarmStatus === 'normal' && [
                  {
                    required: true,
                    message: '请选择关联告警',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-select
                :disabled="currentState === 'view'"
                v-model="form.relatedAlarm"
                :key="ruleList.ruleKey"
                filterable
                multiple
                class="w330"
              >
                <el-option
                  v-for="item in ruleList"
                  :value="item.ruleKey"
                  :key="item.ruleKey"
                  :label="item.ruleName"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'normal'"
        >
          <div class="params-title">恢复内容</div>
          <div class="params-detail">
            <el-form-item
              prop="restoreDetail"
              :rules="
                form.alarmStatus === 'normal' && [
                  {
                    required: true,
                    message: '请输入恢复内容',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-input
                :disabled="currentState === 'view'"
                v-model="form.restoreDetail"
                placeholder="请输入恢复内容"
                class="w330"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm'"
        >
          <div class="params-title">告警级别</div>
          <div class="params-detail">
            <el-select
              :disabled="currentState === 'view'"
              v-model="form.alarmLevel"
              class="w330"
            >
              <el-option
                :label="$t('vlink.rule.info')"
                value="info"
              ></el-option>
              <el-option
                :label="$t('vlink.rule.warn')"
                value="warn"
              ></el-option>
              <el-option
                :label="$t('vlink.rule.error')"
                value="error"
              ></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm'"
        >
          <div class="params-title">告警类型</div>
          <div class="params-detail">
            <el-select
              :disabled="currentState === 'view'"
              v-model="form.alarmType"
              class="w330"
            >
              <el-option :label="$t('vlink.rule.run')" value="run"></el-option>
              <el-option
                :label="$t('vlink.rule.fault')"
                value="fault"
              ></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm'"
        >
          <div class="params-title">告警内容</div>
          <div class="params-detail">
            <el-form-item
              prop="alarmDetail"
              :rules="
                form.alarmStatus === 'alarm' && [
                  {
                    required: true,
                    message: '请输入告警内容',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-input
                :disabled="currentState === 'view'"
                v-model="form.alarmDetail"
                placeholder="请输入告警内容"
                class="w330"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="form.repeatedAlarm === 'N' ? 24 : 12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm'"
        >
          <div class="params-title">是否重复告警</div>
          <div class="params-detail">
            <el-form-item prop="repeatedAlarm">
              <el-switch
                :disabled="currentState === 'view'"
                v-model="form.repeatedAlarm"
                active-value="Y"
                inactive-value="N"
                active-color="#13ce66"
              >
              </el-switch>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm' && form.repeatedAlarm === 'Y'"
        >
          <div class="params-title">最大重复告警次数</div>
          <div class="params-detail">
            <el-form-item
              prop="maxRepeatedCount"
              :rules="
                form.repeatedAlarm === 'Y' && [
                  {
                    required: true,
                    message: '请输入最大重复告警次数',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-input-number
                :disabled="currentState === 'view'"
                v-model="form.maxRepeatedCount"
                :controls="false"
                :min="0"
                :max="10"
                class="w330"
                @change="maxRepeatedCountChange"
              ></el-input-number>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="form.isSendSMS === 'N' ? 24 : 12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm'"
        >
          <div class="params-title">是否发送短信</div>
          <div class="params-detail">
            <el-form-item prop="isSendSMS">
              <el-switch
                :disabled="currentState === 'view'"
                v-model="form.isSendSMS"
                active-value="Y"
                inactive-value="N"
                active-color="#13ce66"
              >
              </el-switch>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm' && form.isSendSMS === 'Y'"
        >
          <div class="params-title">发送手机号集合</div>
          <div class="params-detail">
            <el-form-item
              prop="phones"
              :rules="
                form.isSendSMS === 'Y' && [
                  {
                    required: true,
                    message: '请选择发送手机号集合',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-select
                ref="userSelector"
                @change="userChange"
                :disabled="currentState === 'view'"
                v-model="form.phones"
                multiple
                filterable
                remote
                allow-create
                reserve-keyword
                placeholder="请输入关键词"
                class="w330"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="`${item.userName}(${item.phone})`"
                  :value="item.phone"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="form.isSendEmail === 'N' ? 24 : 12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm'"
        >
          <div class="params-title">是否发送邮件</div>
          <div class="params-detail">
            <el-form-item prop="isSendEmail">
              <el-switch
                :disabled="currentState === 'view'"
                v-model="form.isSendEmail"
                active-value="Y"
                inactive-value="N"
                active-color="#13ce66"
              >
              </el-switch>
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="12"
          class="params-line"
          v-if="form.alarmStatus === 'alarm' && form.isSendEmail === 'Y'"
        >
          <div class="params-title">发送邮件集合</div>
          <div class="params-detail">
            <el-form-item
              prop="emails"
              :rules="
                form.isSendEmail === 'Y' && [
                  {
                    required: true,
                    message: '请选择发送邮件集合',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-select
                ref="emailSelector"
                @change="emailChange"
                :disabled="currentState === 'view'"
                v-model="form.emails"
                multiple
                filterable
                remote
                allow-create
                reserve-keyword
                placeholder="请输入关键词"
                class="w330"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="`${item.userName}(${item.email})`"
                  :value="item.email"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { findRuleList } from "@/api/ruge/vlink/rule/rule";
import { actionComponentMixins } from "@/mixins/actionComponentMixins.js";
import { getUserList } from "@/api/ruge/vlink/rule/rule.js";
import { deepClone } from "@/utils/utils.js";
export default {
  mixins: [actionComponentMixins],
  props: {
    currentState: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        time: 0,
        times: 1,
        alarmStatus: "alarm",
        alarmLevel: "info",
        alarmType: "run",
        alarmDetail: null,
        relatedAlarm: [],
        restoreDetail: null,
        repeatedAlarm: "N",
        maxRepeatedCount: "3",
      },
      ruleList: [],
      loading: false,
      options: [],
      resourceOptions: [],
      resourceEmailOptions: [],
    };
  },
  created() {
    this.getRuleListHandler();
    this.getUserListHandler("", true);
  },
  methods: {
    maxRepeatedCountChange(count) {
      if (count === 0) {
        // this.$message.warning("注意：当最大次数为0时，此告警将无法恢复！");
        this.$confirm(
          `当最大次数为0时，此告警将无法恢复！`,
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            showCancelButton: false,
            type: "warning",
          }
        );
      }
    },
    emailChange($event) {
      this.$refs.emailSelector.query = "";
      this.$emit("change", $event);
      this.checkEmailListValid();
    },
    checkEmailListValid() {
      let emailList = this.form.emails;
      for (let i = 0; i < this.form.emails.length; i++) {
        if (!this.checkEmailValid(this.form.emails[i])) {
          emailList.splice(i, 1);
          this.$message.warning("邮箱格式不正确");
        }
      }
      // 到resourceOptions中查找，如果该手机号码没有，则创建一个option item
      emailList.forEach((email) => {
        if (!this.checkEmailExistInResource(email)) {
          this.resourceEmailOptions.push({
            userName: email,
            email,
          });
        }
      });
    },
    userChange($event) {
      // this.form.phones = [];
      // 搜索完选择下拉项后清除掉输入内容
      this.$refs.userSelector.query = "";
      this.$emit("change", $event);
      this.checkPhoneListValid();
    },
    checkPhoneListValid() {
      // 验证所有手机号码格式是否正确
      let phoneList = this.form.phones;
      for (let i = 0; i < this.form.phones.length; i++) {
        if (!this.checkPhoneValid(this.form.phones[i])) {
          phoneList.splice(i, 1);
          this.$message.warning("手机号码格式不正确");
        }
      }
      console.log("yyyyyyy", this.form.phones);
      // 到resourceOptions中查找，如果该手机号码没有，则创建一个option item
      phoneList.forEach((phone) => {
        if (!this.checkPhoneExistInResource(phone)) {
          this.resourceOptions.push({
            userName: phone,
            phone: phone,
          });
        }
      });
    },
    checkEmailExistInResource(email) {
      for (let i = 0; i < this.resourceEmailOptions.length; i++) {
        if (this.resourceEmailOptions[i].email === email) {
          return true;
        }
      }
      return false;
    },
    checkPhoneExistInResource(phone) {
      for (let i = 0; i < this.resourceOptions.length; i++) {
        if (this.resourceOptions[i].phone === phone) {
          return true;
        }
      }
      return false;
    },
    checkPhoneValid(phoneNumber) {
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      return reg_tel.test(phoneNumber);
    },
    checkEmailValid(email) {
      var pattern =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      return pattern.test(email);
    },
    getUserListHandler(query, initFlag) {
      getUserList({
        filter: query || "",
      })
        .then((res) => {
          this.options = res;
          if (initFlag) {
            console.log("init", this.form.phones);
            this.resourceOptions = res;
            this.resourceEmailOptions = res;
            this.form.phones.forEach((phone) => {
              if (!this.checkPhoneExistInResource(phone)) {
                this.resourceOptions.push({
                  userName: phone,
                  phone: phone,
                });
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getRuleListHandler() {
      findRuleList({ ruleType: "alarm" }).then((res) => {
        this.ruleList = res;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getUserListHandler(query);
      } else {
        this.options = this.resourceOptions;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.alarm-action-comp {
  .detail-line {
    .select-label {
      background: #f8f9f9;
      border-radius: 4px;
      color: #606266;
      text-align: center;
      display: inline-block;
      height: 36px;
      line-height: 36px;
      width: 85px;
    }
    .split-span {
      margin: 0 12px;
    }
    .number-input {
      width: 78px;
    }
  }
  .form-line {
    width: 718px;
    margin-top: 20px;

    .params-title {
      opacity: 0.7;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
    }
    .params-line {
      margin-bottom: 20px;
    }
    .w330 {
      width: 330px;
    }
  }
  ::v-deep.el-input-number.is-without-controls .el-input__inner {
    text-align: left;
  }
}
</style>