<template>
  <div class="normal-condition-component">
    <div class="active-time-line">
      <span class="title-label">生效时间</span>
      <el-switch
        :disabled="currentState === 'view'"
        v-model="activeTimeNeed"
        :active-value="true"
        :inactive-value="false"
        active-color="#13ce66"
      ></el-switch>
      <!-- <span v-if="activeTimeNeed" class="ml10">
        <el-checkbox-group
          v-model="activeTime"
          :disabled="currentState === 'view'"
          size="small"
          style="display: inline-block"
        >
          <el-checkbox-button
            v-for="day in days"
            :label="day.value"
            :key="day.value"
            >{{ day.label }}</el-checkbox-button
          >
        </el-checkbox-group>
        <el-time-picker
          :disabled="currentState === 'view'"
          size="small"
          class="ml10 top3"
          v-model="times.startTime"
          value-format="HH:mm:ss"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="开始时间"
        >
        </el-time-picker>
        <el-time-picker
          :disabled="currentState === 'view'"
          size="small"
          class="ml10 top3"
          v-model="times.endTime"
          value-format="HH:mm:ss"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }"
          placeholder="结束时间"
        >
        </el-time-picker>
      </span> -->
      <span v-if="activeTimeNeed">
        <el-button
          type="primary"
          icon="el-icon-time"
          @click="timeConfigShow = true"
          style="margin-left: 20px"
          >{{
            currentState === "view" ? "查看生效时间" : "配置生效时间"
          }}</el-button
        >
      </span>
    </div>
    <div class="rule-main-container">
      <div class="title-line">当以下</div>
      <div class="detail-line">
        <div class="detail-title">
          <el-radio-group v-model="logic" :disabled="currentState === 'view'">
            <el-radio-button label="OR">条件之一</el-radio-button>
            <el-radio-button
              label="AND"
              :disabled="currentRuleType === 'serverSub'"
              >所有条件</el-radio-button
            >
          </el-radio-group>
          <span class="title-after">被满足时，</span>
        </div>
        <div
          class="rule-detail-line"
          v-for="(item, index) in conditionList"
          :key="index"
        >
          <el-form :model="item" :ref="'conditionForm_' + index" :rules="rules">
            <div class="rule-line">
              <span class="index-span">{{ "条件" + (index + 1) }}</span>
              <span class="split-span">当</span>
              <!-- <el-form-item prop="productOrDevice">
                <el-cascader
                  :disabled="currentState === 'view'"
                  class="w360"
                  clearable
                  placeholder="产品/设备"
                  popper-class="product-device-poper"
                  v-model="item.productOrDevice"
                  @change="(value) => productOrDeviceChange(value, index)"
                  :props="props"
                ></el-cascader>
              </el-form-item> -->
              <el-form-item prop="product">
                <el-select
                  :disabled="currentState === 'view'"
                  v-model="item.product"
                  placeholder="产品"
                  filterable
                  clearable
                  class="w175"
                  @change="(value) => productChange(value, index)"
                >
                  <el-option
                    v-for="item in productOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="device">
                <el-select
                  :disabled="currentState === 'view'"
                  v-model="item.device"
                  placeholder="设备"
                  class="w175 ml10"
                  filterable
                  remote
                  clearable
                  @focus="deviceSelectFocus(index)"
                  @change="(value) => deviceSelectChange(value, index)"
                  :remote-method="(value) => deviceRemoteMethod(value, index)"
                  :loading="loadings[index]"
                >
                  <el-option
                    v-for="item in deviceOptions[index]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <span class="split-span">中的</span>
              <el-form-item
                prop="resouceOrModule"
                :rules="[
                  {
                    required: true,
                    validator: (rule, value, callback) =>
                      validatorResourceAndModule(rule, value, callback, index),
                    trigger: ['blur', 'change'],
                  },
                ]"
              >
                <el-select
                  :class="
                    !item.sourceType ||
                    !['property', 'event'].includes(item.sourceType)
                      ? 'w360'
                      : 'w120'
                  "
                  :disabled="currentState === 'view'"
                  v-model="item.sourceType"
                  placeholder="数据源"
                  @change="sourceTypeChange(item)"
                >
                  <el-option
                    v-for="item in item.resourceOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-cascader
                  v-if="
                    item.sourceType === 'property' ||
                    item.sourceType === 'event'
                  "
                  :disabled="currentState === 'view'"
                  placeholder="模块"
                  class="w230 ml8"
                  collapse-tags
                  :props="{ multiple: item.sourceType === 'property' }"
                  v-model="item.resouceOrModule"
                  :key="'resource_' + item.productOrDevice"
                  :options="item.dataResourceOptions[item.sourceType]"
                  @change="(value) => dataResourceChange(value, index)"
                ></el-cascader>
              </el-form-item>
              <span
                class="delete-condition-button"
                v-if="conditionList.length > 1"
              >
                <el-button
                  :disabled="currentState === 'view'"
                  type="danger"
                  size="small"
                  plain
                  v-if="source !== 'dataset'"
                  @click="deleteCondition(index)"
                  >删除条件</el-button
                >
              </span>
            </div>
            <!-- 选择了数据源后才展示规则行 -->
            <div v-if="item.sourceType">
              <!-- 自定义topic类型 -->
              <div
                class="rule-line second-line topic-line"
                v-if="item.sourceType === 'custom'"
              >
                <span class="split-span">符合</span>
                <el-form-item
                  prop="topicSelect"
                  :rules="
                    item.sourceType === 'custom' && [
                      {
                        required: true,
                        message: '请选择Topic',
                        trigger: ['blur', 'change'],
                      },
                    ]
                  "
                >
                  <el-select
                    :disabled="currentState === 'view'"
                    class="w360"
                    multiple
                    filterable
                    clearable
                    v-model="item.topicSelect"
                  >
                    <el-option
                      v-for="topic in item.topicList"
                      :label="topic.topicShortName"
                      :value="topic.topicShortName"
                      :key="topic.topicId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <span class="split-span">时，</span>
              </div>

              <!-- 属性类型 || 状态类型 || 所有属性 -->
              <!-- 状态类型的运算符：固定为等于，不可修改，且不能添加规则 -->
              <div
                v-else-if="
                  item.sourceType === 'property' ||
                  item.sourceType === 'status' ||
                  item.sourceType === '@ALLPROPERTY@'
                "
              >
                <div class="rule-line second-line">
                  <span class="split-span">在</span>
                  <el-input
                    :disabled="currentState === 'view'"
                    v-model="item.time"
                    placeholder="时效"
                    class="w100"
                  ></el-input>
                  <span class="split-span">秒内的</span>
                  <el-select
                    :disabled="currentState === 'view'"
                    class="w194"
                    v-model="item.condition"
                    placeholder="请选择触发条件"
                  >
                    <el-option
                      v-for="item in TriggerConditionList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <span class="split-span">符合</span>
                  <el-form-item
                    prop="operator"
                    :rules="[
                      {
                        required: true,
                        validator: (rule, value, callback) =>
                          validatorOperator(rule, value, callback, index, 0),
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-select
                      placeholder="关系运算符"
                      v-model="item.ruleList[0].operator"
                      :disabled="
                        item.sourceType === 'status' || currentState === 'view'
                      "
                      @change="(value) => operatorChange(value, index, 0)"
                      clearable
                      class="w120 mr8"
                    >
                      <el-option
                        v-for="item in OperatorList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    prop="value"
                    :rules="[
                      {
                        required: true,
                        validator: (rule, value, callback) =>
                          validatorValue(rule, value, callback, index, 0),
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <!-- 当操作符为 大于、小于、大于等于、小于等于时，input为number类型 -->
                    <el-input
                      v-if="
                        item.sourceType === 'property' ||
                        item.sourceType === '@ALLPROPERTY@'
                      "
                      v-model="item.ruleList[0].value"
                      :disabled="
                        item.ruleList[0].operator === 'isNull' ||
                        item.ruleList[0].operator === 'isNotNull' ||
                        currentState === 'view'
                      "
                      placeholder="值"
                      :type="
                        ['>', '<', '>=', '<='].includes(
                          item.ruleList[0].operator
                        ) && item.sourceType === 'property'
                          ? 'number'
                          : ''
                      "
                      class="w232"
                    ></el-input>
                    <el-select
                      :disabled="currentState === 'view'"
                      v-else
                      placeholder="比较值"
                      v-model="item.ruleList[0].value"
                      class="w232"
                    >
                      <!-- 数据流转类型下的状态，才能选到所有状态 -->
                      <el-option
                        v-show="
                          currentRuleType === 'serverSub' &&
                          item.sourceType === 'status'
                        "
                        value="@ALLSTATUS@"
                        label="所有状态"
                      ></el-option>
                      <el-option value="ONLINE" label="在线"></el-option>
                      <el-option value="OFFLINE" label="离线"></el-option>
                    </el-select>
                  </el-form-item>
                  <span class="split-span">时，</span>
                  <el-tooltip
                    v-if="
                      item.sourceType === 'property' && source !== 'dataset'
                    "
                    class="item"
                    effect="dark"
                    content="添加规则"
                    placement="bottom"
                  >
                    <el-button
                      :disabled="currentState === 'view'"
                      class="delete-rule-button"
                      v-if="item.ruleList.length === 1"
                      @click="addOperationRule(index)"
                      icon="el-icon-plus"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div
                  class="rule-line last-line"
                  v-for="(rule, i) in item.ruleList"
                  :key="i"
                >
                  <div v-if="i > 0">
                    <span class="split-span">并且符合</span>
                    <el-form-item
                      prop="operator"
                      :rules="[
                        {
                          required: true,
                          validator: (rule, value, callback) =>
                            validatorOperator(rule, value, callback, index, i),
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-select
                        :disabled="currentState === 'view'"
                        placeholder="关系运算符"
                        v-model="rule.operator"
                        @change="(value) => operatorChange(value, index, i)"
                        clearable
                        class="w120 mr8"
                      >
                        <el-option
                          v-for="item in OperatorList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      prop="value"
                      :rules="[
                        {
                          required: true,
                          validator: (rule, value, callback) =>
                            validatorValue(rule, value, callback, index, i),
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <!-- 当操作符为 大于、小于、大于等于、小于等于时，input为number类型 -->
                      <el-input
                        v-model="rule.value"
                        :disabled="
                          rule.operator === 'isNull' ||
                          rule.operator === 'isNotNull' ||
                          currentState === 'view'
                        "
                        :type="
                          ['>', '<', '>=', '<='].includes(
                            item.ruleList[i].operator
                          ) && item.sourceType === 'property'
                            ? 'number'
                            : ''
                        "
                        placeholder="值"
                        class="w232"
                      ></el-input>
                    </el-form-item>
                    <span class="split-span">时，</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="删除规则"
                      placement="bottom"
                    >
                      <el-button
                        :disabled="currentState === 'view'"
                        class="delete-rule-button"
                        v-if="i > 0"
                        @click="deleteOperationRule(index, i)"
                        icon="el-icon-delete"
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="添加规则"
                      placement="bottom"
                    >
                      <el-button
                        :disabled="currentState === 'view'"
                        class="delete-rule-button"
                        v-if="i === item.ruleList.length - 1"
                        @click="addOperationRule(index)"
                        icon="el-icon-plus"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>

              <!-- 事件类型 -->
              <div v-else-if="item.sourceType === 'event'">
                <div class="rule-line second-line">
                  <span class="split-span">在</span>
                  <el-input
                    :disabled="currentState === 'view'"
                    v-model="item.time"
                    placeholder="时效"
                    class="w100"
                  ></el-input>
                  <span class="split-span">秒内，符合</span>
                  <el-form-item
                    prop="outputparams"
                    :rules="[
                      {
                        required: true,
                        validator: (rule, value, callback) =>
                          validatorOutputparams(
                            rule,
                            value,
                            callback,
                            index,
                            0
                          ),
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-select
                      :disabled="currentState === 'view'"
                      class="w211"
                      clearable
                      v-model="item.ruleList[0].outputparams"
                      placeholder="上报参数名称"
                    >
                      <el-option
                        v-for="item in item.eventParamsList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    prop="operator"
                    :rules="[
                      {
                        required: true,
                        validator: (rule, value, callback) =>
                          validatorEventOperator(
                            rule,
                            value,
                            callback,
                            index,
                            0
                          ),
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-select
                      :disabled="currentState === 'view'"
                      placeholder="关系运算符"
                      v-model="item.ruleList[0].operator"
                      clearable
                      @change="(value) => operatorChange(value, index, 0)"
                      class="w120 mr8 ml8"
                    >
                      <el-option
                        v-for="item in OperatorList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    prop="value"
                    :rules="[
                      {
                        required: true,
                        validator: (rule, value, callback) =>
                          validatorEventValue(rule, value, callback, index, 0),
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-input
                      v-model="item.ruleList[0].value"
                      :disabled="
                        item.ruleList[0].operator === 'isNull' ||
                        item.ruleList[0].operator === 'isNotNull' ||
                        currentState === 'view'
                      "
                      :type="
                        ['>', '<', '>=', '<='].includes(
                          item.ruleList[0].operator
                        )
                          ? 'number'
                          : ''
                      "
                      placeholder="值"
                      class="w232"
                    ></el-input>
                  </el-form-item>

                  <span class="split-span">时，</span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="添加规则"
                    placement="bottom"
                  >
                    <el-button
                      :disabled="currentState === 'view'"
                      class="delete-rule-button"
                      v-if="item.ruleList.length === 1"
                      @click="addOperationRule(index)"
                      icon="el-icon-plus"
                    ></el-button>
                  </el-tooltip>
                </div>
                <div
                  class="rule-line event-last-line"
                  v-for="(rule, i) in item.ruleList"
                  :key="i"
                >
                  <div v-if="i > 0">
                    <span class="split-span">并且符合</span>
                    <el-form-item
                      prop="outputparams"
                      :rules="[
                        {
                          required: true,
                          validator: (rule, value, callback) =>
                            validatorOutputparams(
                              rule,
                              value,
                              callback,
                              index,
                              i
                            ),
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-select
                        :disabled="currentState === 'view'"
                        class="w211"
                        v-model="rule.outputparams"
                        placeholder="上报参数名称"
                      >
                        <el-option
                          v-for="item in item.eventParamsList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      prop="operator"
                      :rules="[
                        {
                          required: true,
                          validator: (rule, value, callback) =>
                            validatorEventOperator(
                              rule,
                              value,
                              callback,
                              index,
                              i
                            ),
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-select
                        :disabled="currentState === 'view'"
                        placeholder="关系运算符"
                        v-model="rule.operator"
                        @change="(value) => operatorChange(value, index, i)"
                        clearable
                        class="w120 mr8 ml8"
                      >
                        <el-option
                          v-for="item in OperatorList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      prop="value"
                      :rules="[
                        {
                          required: true,
                          validator: (rule, value, callback) =>
                            validatorEventValue(
                              rule,
                              value,
                              callback,
                              index,
                              i
                            ),
                          trigger: ['blur', 'change'],
                        },
                      ]"
                    >
                      <el-input
                        v-model="rule.value"
                        :disabled="
                          rule.operator === 'isNull' ||
                          rule.operator === 'isNotNull' ||
                          currentState === 'view'
                        "
                        :type="
                          ['>', '<', '>=', '<='].includes(
                            item.ruleList[0].operator
                          )
                            ? 'number'
                            : ''
                        "
                        placeholder="值"
                        class="w232"
                      ></el-input>
                    </el-form-item>

                    <span class="split-span">时，</span>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="删除规则"
                      placement="bottom"
                    >
                      <el-button
                        :disabled="currentState === 'view'"
                        class="delete-rule-button"
                        v-if="i > 0"
                        @click="deleteOperationRule(index, i)"
                        icon="el-icon-delete"
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="添加规则"
                      placement="bottom"
                    >
                      <el-button
                        :disabled="currentState === 'view'"
                        class="delete-rule-button"
                        v-if="i === item.ruleList.length - 1"
                        @click="addOperationRule(index)"
                        icon="el-icon-plus"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="button-container">
          <el-button
            type="primary"
            :disabled="currentState === 'view'"
            plain
            v-if="source !== 'dataset'"
            @click="addCondition"
            >添加条件</el-button
          >
        </div>
      </div>
    </div>
    <TimeConfigComponent
      v-if="timeConfigShow"
      :show="timeConfigShow"
      :dataset="activeTimeDataset"
      :disabled="currentState === 'view'"
      @close="timeConfigShow = false"
      @submit="activeTimeSubmit"
    />
  </div>
</template>

<script>
import { deepClone } from "@/utils/utils.js";
import {
  getProductList,
  findTsl,
  findCustomTopicList,
} from "@/api/ruge/vlink/product/product";
import { findDeviceList, findDevicePage } from "@/api/ruge/vlink/device/device";
import { checkRuleEventLogic } from "@/utils/utils.js";
import TimeConfigComponent from "./timeConfigComponent.vue";
const days = [
  {
    label: "星期天",
    value: "sun",
  },
  {
    label: "星期一",
    value: "mon",
  },
  {
    label: "星期二",
    value: "tue",
  },
  {
    label: "星期三",
    value: "wed",
  },
  {
    label: "星期四",
    value: "thu",
  },
  {
    label: "星期五",
    value: "fri",
  },
  {
    label: "星期六",
    value: "sat",
  },
];
/**
 * linkage: 联动规则
 * alarm: 告警规则
 * schedule: 时间表
 * deviceCall: 设备通信
 * serverSub: 服务端订阅
 */
const DataResourceTotalList = {
  linkage: [
    // { label: "所有属性", value: "@ALLPROPERTY@" },
    { label: "属性", value: "property" },
    { label: "事件", value: "event" },
    { label: "状态", value: "status" },
  ],
  alarm: [
    // { label: "所有属性", value: "@ALLPROPERTY@" },
    { label: "属性", value: "property" },
    { label: "状态", value: "status" },
    { label: "事件", value: "event" },
  ],
  deviceCall: [
    // { label: "所有属性", value: "@ALLPROPERTY@" },
    { label: "属性", value: "property" },
    { label: "事件", value: "event" },
    { label: "状态", value: "status" },
    { label: "告警", value: "alarm" },
    { label: "消息", value: "message" },
    { label: "自定义Topic", value: "custom" },
    { label: "服务调用", value: "service" },
    { label: "设备日志", value: "log" },
  ],
  serverSub: [
    { label: "所有属性", value: "@ALLPROPERTY@" },
    { label: "属性", value: "property" },
    { label: "事件", value: "event" },
    { label: "状态", value: "status" },
    { label: "告警", value: "alarm" },
    { label: "消息", value: "message" },
    { label: "自定义Topic", value: "custom" },
    { label: "服务调用", value: "service" },
    { label: "属性设置", value: "propertySet" },
    { label: "设备日志", value: "log" },
    { label: "产品", value: "product" },
    { label: "设备", value: "device" },
    { label: "设备Topo", value: "deviceTopo" },
    { label: "协议通道", value: "protocolChannel" },
    { label: "产品标签", value: "productTag" },
    { label: "设备标签", value: "deviceTag" },
    { label: "网关部署", value: "gatewayDeploy" },
  ],
};
// const DataResourceTotalList = {
//   linkage: [
//     { label: "属性", value: "property", children: [] },
//     { label: "事件", value: "event", children: [] },
//     { label: "状态", value: "status" },
//   ],
//   alarm: [
//     { label: "属性", value: "property", children: [] },
//     { label: "状态", value: "status" },
//   ],
//   deviceCall: [
//     { label: "属性", value: "property", children: [] },
//     { label: "事件", value: "event", children: [] },
//     { label: "状态", value: "status" },
//     { label: "告警", value: "alarm" },
//     { label: "消息", value: "message" },
//     { label: "自定义Topic", value: "custom" },
//     { label: "服务调用", value: "service" },
//     { label: "设备日志", value: "log" },
//   ],
//   serverSub: [
//     { label: "属性", value: "property", children: [] },
//     { label: "事件", value: "event", children: [] },
//     { label: "状态", value: "status" },
//     { label: "告警", value: "alarm" },
//     { label: "消息", value: "message" },
//     { label: "自定义Topic", value: "custom" },
//     { label: "服务调用", value: "service" },
//     { label: "设备日志", value: "log" },
//     { label: "产品", value: "product" },
//     { label: "设备", value: "device" },
//     { label: "设备Topo", value: "deviceTopo" },
//     { label: "协议通道", value: "protocolChannel" },
//     { label: "产品标签", value: "productTag" },
//     { label: "设备标签", value: "deviceTag" },
//     { label: "网关部署", value: "gatewayDeploy" },
//   ],
// };
const EmptyLine = {
  productOrDevice: [],
  product: "",
  device: "",
  resouceOrModule: null,
  time: 120,
  condition: "reverse",
  ruleList: [
    {
      operator: null,
      value: null,
      outputparams: [],
    },
  ],
  dataResourceOptions: null,
  sourceType: "",
  topicSelect: null,
  topicList: [],
  eventParamsList: [],
  resourceOptions: [],
};
const EmptyOperationLine = {
  operator: null,
  value: null,
};
const TriggerConditionList = [
  { label: "变更触发", value: "reverse" },
  { label: "每次触发", value: "pulse" },
];
const OperatorList = [
  { label: "为空", value: "isNull" },
  { label: "不为空", value: "isNotNull" },
  { label: "大于", value: ">" },
  { label: "小于", value: "<" },
  { label: "大于等于", value: ">=" },
  { label: "小于等于", value: "<=" },
  { label: "等于", value: "==" },
  { label: "包含", value: "isInclude" },
  { label: "不包含", value: "isNotInclude" },
];
export default {
  props: {
    currentRuleType: {
      type: String,
      required: true,
    },
    currentRowData: {
      type: Object,
      required: false,
    },
    currentState: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default() {
        return "url";
      },
    },
  },
  components: {
    TimeConfigComponent,
  },
  watch: {
    currentRuleType: {
      handler(ruleType) {
        console.log("ruleType", ruleType);
        this.buildDataResourceList(ruleType);
      },
      immediate: true,
    },
    currentRowData: {
      handler(val) {
        if (!val) return;
        this.displayRuleDatas(val);
      },
      immediate: true,
    },
  },
  data() {
    const validatorOperator = (
      rule,
      value,
      callback,
      conditionIndex,
      ruleIndex
    ) => {
      // 如果ruleList中的值有填，那么操作符也是必填
      if (
        this.conditionList[conditionIndex].ruleList[ruleIndex].value &&
        !this.conditionList[conditionIndex].ruleList[ruleIndex].operator &&
        this.conditionList[conditionIndex].sourceType !== "status"
      ) {
        callback(new Error("请选择关系运算符"));
      } else {
        callback();
      }
    };
    const validatorValue = (
      rule,
      value,
      callback,
      conditionIndex,
      ruleIndex
    ) => {
      const curOperator =
        this.conditionList[conditionIndex].ruleList[ruleIndex].operator;
      const curRuleList = this.conditionList[conditionIndex].ruleList;
      const curCondition = this.conditionList[conditionIndex];
      // 大于小于值区间的校验
      if (curRuleList.length > 1 && curCondition.sourceType === "property") {
        // 如果只有一条数据，则不校验
        const checkResult = checkRuleEventLogic(curRuleList, "");
        if (!checkResult) {
          callback(`值的填写逻辑错误`);
          return;
        }
      }
      // 如果ruleList中的操作符有选择（并且不为空或者为空），那么值也是必填
      if (
        curOperator &&
        curOperator !== "isNull" &&
        curOperator !== "isNotNull" &&
        !this.conditionList[conditionIndex].ruleList[ruleIndex].value
      ) {
        callback(new Error("请填写值"));
      } else {
        callback();
      }
    };
    const validatorOutputparams = (
      rule,
      value,
      callback,
      conditionIndex,
      ruleIndex
    ) => {
      const curRule = this.conditionList[conditionIndex].ruleList[ruleIndex];
      // 如果运算符有选择，那上报参数必须选择
      if (curRule.operator && !curRule.outputparams) {
        callback(new Error("请选择上报参数名"));
        return;
      }
      callback();
    };
    const validatorEventOperator = (
      rule,
      value,
      callback,
      conditionIndex,
      ruleIndex
    ) => {
      const curRule = this.conditionList[conditionIndex].ruleList[ruleIndex];
      // 如果选择上报参数必选
      if (curRule.outputparams && !curRule.operator) {
        callback(new Error("请选择运算符"));
        return;
      }
      callback();
    };
    const validatorEventValue = (
      rule,
      value,
      callback,
      conditionIndex,
      ruleIndex
    ) => {
      const curRule = this.conditionList[conditionIndex].ruleList[ruleIndex];
      const curRuleList = this.conditionList[conditionIndex].ruleList;
      console.log("xxxx1", curRuleList);
      // 同一参数下大于小于值区间的校验
      if (curRuleList.length > 1) {
        // 如果只有一条数据，则不校验
        const checkResult = checkRuleEventLogic(
          curRuleList,
          curRule["outputparams"]
        );
        if (!checkResult) {
          callback(`参数${curRule["outputparams"]}值的填写逻辑错误`);
          return;
        }
      }
      // 如果选择上报参数，并且运算符不为“空”，不为“不为空”
      if (
        curRule.outputparams &&
        curRule.operator !== "isNull" &&
        curRule.operator !== "isNotNull" &&
        !curRule.value
      ) {
        callback(new Error("请输入值"));
        return;
      }
      callback();
    };
    const validatorResourceAndModule = (
      rule,
      value,
      callback,
      conditionIndex,
      ruleIndex
    ) => {
      const curCondition = this.conditionList[conditionIndex];
      if (!curCondition.sourceType) {
        callback(new Error("请选择数据源"));
        return;
      }
      if (
        curCondition.sourceType === "property" &&
        !curCondition.resouceOrModule
      ) {
        callback(new Error("请选择模块/属性"));
        return;
      }
      if (
        curCondition.sourceType === "event" &&
        !curCondition.resouceOrModule
      ) {
        callback(new Error("请选择模块/事件"));
        return;
      }
      callback();
    };
    return {
      days,
      validatorOperator,
      validatorValue,
      validatorOutputparams,
      validatorEventOperator,
      validatorEventValue,
      validatorResourceAndModule,
      activeTimeConfig: {},
      timeConfigShow: false,
      activeTimeDataset: "",
      rules: {
        product: [
          {
            required: true,
            message: "请选择产品",
            trigger: ["blur", "change"],
          },
        ],
        // productOrDevice: [
        //   {
        //     required: true,
        //     message: "请选择产品/设备",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // resouceOrModule: [
        //   {
        //     required: true,
        //     message: "请选择数据源/模块",
        //     trigger: ["blur", "change"],
        //   },
        // ],
      },
      TriggerConditionList,
      OperatorList,
      logic: "OR",
      activeTimeNeed: false,
      activeTime: [],
      times: {
        startTime: null,
        endTime: null,
      },
      conditionList: [
        {
          productOrDevice: [],
          product: "",
          device: "",
          resouceOrModule: null,
          time: 120,
          condition: "reverse",
          ruleList: [
            {
              operator: null,
              value: null,
              outputparams: [],
            },
          ],
          dataResourceOptions: null,
          sourceType: "",
          topicSelect: null,
          topicList: [],
          eventParamsList: [],
          resourceOptions: [],
        },
      ],
      // props: {
      //   lazy: true,
      //   checkStrictly: true,
      //   lazyLoad(node, resolve) {
      //     if (!node) return;
      //     console.log("node", node);
      //     const { level, value } = node;
      //     if (level == 0) {
      //       getProductList().then((productList) => {
      //         const nodes = productList.map((item) => ({
      //           value: item.productKey,
      //           label: item.productName,
      //         }));
      //         nodes.sort((item1, item2) => {
      //           return item1.label.localeCompare(item2.label, "zh-CN");
      //         });
      //         resolve(nodes);
      //       });
      //     } else if (level == 1) {
      //       const productKey = value;
      //       findDeviceList({ productKey: productKey }).then((deviceList) => {
      //         const nodes = deviceList.map((item) => ({
      //           value: item.deviceName,
      //           label: item.deviceName,
      //           leaf: item.deviceName,
      //         }));
      //         resolve(nodes.length > 0 ? nodes : false);
      //       });
      //     } else {
      //       // 选到设备时就不加载了。。否则一直loading
      //       resolve([]);
      //     }
      //   },
      // },
      productOptions: [],
      deviceOptions: {},
      loadings: {},
      deviceQueryParams: {
        current: 1,
        rowCount: 50,
        description: null,
        productKey: null,
      },
    };
  },
  created() {
    this.getProductListHandler();
  },
  methods: {
    activeTimeSubmit(params) {
      this.activeTimeDataset = params.extend;
      this.activeTimeConfig = params;
      this.timeConfigShow = false;
    },
    operatorChange(value, conditionIndex, ruleIndex) {
      console.log("value,index", value, conditionIndex, ruleIndex);
      if (["isNotNull", "isNull"].includes(value)) {
        this.conditionList[conditionIndex].ruleList[ruleIndex].value = null;
      }
    },
    deviceSelectFocus(index) {
      if (
        !this.conditionList[index].product &&
        !this.conditionList[index].device
      ) {
        this.deviceRemoteMethod("", index);
      }
    },
    deviceSelectChange(selectDevice, index) {
      if (!this.conditionList[index].product) {
        this.conditionList[index].product = this.deviceOptions[index].filter(
          (item) => item.value === selectDevice
        )[0].productKey;
      }
    },
    deviceRemoteMethod(query, index, product) {
      const selectedProduct =
        product || this.conditionList[index].product || "";
      let params = this.deviceQueryParams;
      if (selectedProduct) {
        params.productKey = selectedProduct;
      } else {
        delete params.productKey;
      }
      params.description = query;
      // 如果该条件选择了产品，则按产品查询
      // if (selectedProduct) {
      this.$set(this.loadings, index, true);
      findDevicePage(params)
        .then((res) => {
          const currentDevices = res.rows.map((item) => {
            return {
              productKey: item.productKey,
              value: item.deviceName,
              label: item.description
                ? `${item.description} (${item.deviceName})`
                : `${item.deviceName} (${item.deviceName})`,
            };
          });
          this.$set(this.deviceOptions, index, currentDevices);
        })
        .finally(() => {
          this.$set(this.loadings, index, false);
        });
      // } else {
      //   // 如果没有选择，则默认模糊查询，选择后反选产品
      //   findDevicePage(params)
      //     .then((res) => {
      //       const currentDevices = res.rows.map((item) => {
      //         return {
      //           value: item.deviceName,
      //           label: item.description
      //             ? `${item.description} (${item.deviceName})`
      //             : `${item.deviceName} (${item.deviceName})`,
      //         };
      //       });
      //       this.$set(this.deviceOptions, index, currentDevices);
      //     })
      //     .finally(() => {
      //       this.$set(this.loadings, index, false);
      //     });
      // }

      // if (product || this.conditionList[index].product) {
      //   this.$set(this.loadings, index, true);
      //   let params = this.deviceQueryParams;
      //   params.description = query;
      //   params.productKey = product || this.conditionList[index].product || "";
      //   findDevicePage(params)
      //     .then((res) => {
      //       const currentDevices = res.rows.map((item) => {
      //         return {
      //           value: item.deviceName,
      //           label: item.description
      //             ? `${item.description} (${item.deviceName})`
      //             : `${item.deviceName} (${item.deviceName})`,
      //         };
      //       });
      //       this.$set(this.deviceOptions, index, currentDevices);
      //     })
      //     .finally(() => {
      //       this.$set(this.loadings, index, false);
      //     });
      // } else {
      //   this.$set(this.deviceOptions, index, []);
      // }
    },
    getProductListHandler() {
      console.log("xxx1");
      getProductList().then((productList) => {
        const nodes = productList.map((item) => ({
          value: item.productKey,
          label: item.productName,
        }));
        nodes.sort((item1, item2) => {
          return item1.label.localeCompare(item2.label, "zh-CN");
        });
        this.productOptions = nodes;
        console.log("this.productOptions", this.productOptions);
      });
    },
    productChange(value, index) {
      // 清空一选择的设备、属性
      this.conditionList[index].device = "";
      this.conditionList[index].resouceOrModule = "";
      this.conditionList[index].sourceType = "";
      this.$set(this.deviceOptions, index, []);
      // 根据产品获取设备
      this.deviceRemoteMethod("", index);
      // 根据产品获取物模型
      if (value) {
        this.getTslHandler(value, index);
      }
    },
    productOrDeviceChange(value, index, flag) {
      // 清空数据/模块的选择 !回显的时候不需要清空
      if (!flag) {
        this.conditionList[index].resouceOrModule = "";
        this.conditionList[index].sourceType = "";
      }
      // 取productKey去获取tsl
      if (value && value.length > 0) {
        this.getTslHandler(value[0], index);
      }
    },
    // 切换数据源时，清空value值
    sourceTypeChange(item) {
      const { sourceType } = item || {};
      item.ruleList[0].operator = sourceType === "status" ? "==" : "";
      item.ruleList.length = 1;
      if (item.ruleList[0]) {
        item.ruleList[0].value = "";
      }
    },
    displayRuleDatas(ruleDetail) {
      // 直接通过extendConfig回显即可
      const curConditionList = JSON.parse(
        JSON.parse(ruleDetail.ruleConfig).conditionGroup.extendConfig
      );
      if (curConditionList.length) {
        curConditionList.forEach((item, index) => {
          item.product = item.productOrDevice[0];
          item.device = item.productOrDevice[1];
          this.deviceRemoteMethod("", index, item.product);
        });
      }
      this.conditionList = curConditionList;
      // 回显时更新物模型的事件、属性等
      this.conditionList.forEach((item) => {
        findTsl({ productKey: item.product }).then((res) => {
          const treeDatas = this.buildTreeDatas(res.modules);
          item.dataResourceOptions = treeDatas;
        });
      });
      const curConditionGroup = JSON.parse(
        ruleDetail.ruleConfig
      ).conditionGroup;
      this.logic = curConditionGroup.logic;
      this.activeTimeNeed = curConditionGroup.activeTime ? true : false;
      if (curConditionGroup.activeTime) {
        this.activeTime = curConditionGroup.activeTime;
        this.activeTimeDataset = curConditionGroup.timeExtendConfig;
        this.activeTimeConfig.config = curConditionGroup.activeTime;
        this.activeTimeConfig.extend = curConditionGroup.timeExtendConfig;
      }
    },
    checkValidAndEmitDatas() {
      if (this.activeTimeNeed && !this.activeTimeConfig.config) {
        this.$message.warning("请选择生效时间！");
        return false;
      }
      let validList = [];
      const curConditionLength = this.conditionList.length;
      this.conditionList.forEach((item, index) => {
        validList.push(this.singleFormValid(index));
      });
      return Promise.all(validList).then((res) => {
        if (res.filter((item) => item === true).length === curConditionLength) {
          return this.buildConditionList();
        } else {
          console.log("表单必填验证不通过！");
        }
      });
    },
    buildConditionList() {
      console.log("conditionList:", this.conditionList);
      this.conditionList.forEach((item) => {
        item.productOrDevice = [];
        if (item.product) {
          item.productOrDevice.push(item.product);
        }
        if (item.device) {
          item.productOrDevice.push(item.device);
        }
      });
      let resultObj = {
        logic: this.logic,
        activeTime: null,
        extendConfig: JSON.stringify(this.conditionList),
        conditions: [],
      };
      if (this.activeTimeNeed) {
        resultObj.activeTime = this.activeTimeConfig.config;
        resultObj.timeExtendConfig = this.activeTimeConfig.extend;
      }
      this.conditionList.forEach((item) => {
        let tempObj = {
          type: item.productOrDevice.length === 1 ? "product" : "device",
          condition: {
            productKey: item.productOrDevice[0],
            deviceName: item.productOrDevice[1] || null,
            type: item.sourceType,
            filters: [
              // {
              //   eventName: null,
              //   moduleName: null,
              //   operator: null,
              //   paramName: null,
              //   propertyName: null,
              //   topics: [],
              //   value: null,
              // },
            ],
            strategy: {
              trigger: item.condition,
              validTime: item.time,
            },
          },
        };
        // 自定义topic类型数据拼装
        if (item.sourceType === "custom") {
          tempObj.condition.filters = [
            {
              operator: null,
              value: null,
              topics: item.topicSelect,
            },
          ];
        }
        // 所有属性类型
        if (item.sourceType === "@ALLPROPERTY@") {
          tempObj.condition.type = "property";
          tempObj.condition.filters = item.ruleList.map((val) => {
            return {
              operator: val.operator,
              value: val.value,
              moduleName: "@ALLMODULE@",
              propertyName: "@ALLPROPERTY@",
            };
          });
        }
        // 属性类型数据拼装
        if (item.sourceType === "property") {
          item.resouceOrModule.forEach((element) => {
            let tempObject = {
              type: item.productOrDevice.length === 1 ? "product" : "device",
              condition: {
                deviceName: item.productOrDevice[1] || null,
                productKey: item.productOrDevice[0],
                type: item.sourceType,
                filters: [
                  // {
                  //   operator: val.operator,
                  //   value: val.value,
                  //   moduleName: element[0],
                  //   propertyName: element[1],
                  // },
                ],
                strategy: {
                  trigger: item.condition,
                  validTime: item.time,
                },
              },
            };
            item.ruleList.forEach((val) => {
              tempObject.condition.filters.push({
                operator: val.operator,
                value: val.value,
                moduleName: element[0],
                propertyName: element[1],
              });
            });
            resultObj.conditions.push(tempObject);
          });
          return resultObj;
        }
        // 事件类型数据拼装
        if (item.sourceType === "event") {
          tempObj.condition.filters = item.ruleList.map((val) => {
            return {
              operator: val.operator,
              value: val.value,
              paramName: val.outputparams,
              moduleName: item.resouceOrModule[0],
              eventName: item.resouceOrModule[1],
            };
          });
        }
        // 状态类型数据拼装
        if (item.sourceType === "status") {
          tempObj.condition.filters = item.ruleList.map((val) => {
            return {
              operator: val.operator || "==",
              value: val.value,
            };
          });
        }
        resultObj.conditions.push(tempObj);
      });
      console.log("resultObj", resultObj);
      return resultObj;
    },
    singleFormValid(index) {
      return new Promise((resolve, reject) => {
        this.$refs["conditionForm_" + index][0].validate((valid) => {
          if (valid) {
            resolve(valid);
          }
        });
      }).catch((e) => {});
    },
    dataResourceChange(value, index) {
      console.log("value,index", value, index);
      /**
       * sourceType：
       * 自定义topic类型：custom
       * 属性类型：property
       * 事件类型：event
       * 状态类型：status
       * 其他：others
       */
      const curDataSource = this.conditionList[index].sourceType;
      const productKey = this.conditionList[index].product;
      switch (curDataSource) {
        // 自定义topic类型
        case "custom":
          findCustomTopicList({ productKey }).then((topicList) => {
            this.conditionList[index].topicList = topicList;
          });
          this.conditionList[index].sourceType = "custom";
          break;
        // 属性类型
        case "property":
          this.conditionList[index].sourceType = "property";
          break;
        case "event":
          // 事件类型
          findTsl({ productKey }).then((res) => {
            // 清空此条件下所有的outputparams
            this.conditionList[index].ruleList.forEach((rule) => {
              rule.outputparams = "";
            });
            // 根据模块名称匹配该模块下事件的参数列表
            res.modules.forEach((item) => {
              if (item.moduleName === value[0]) {
                let tempList = [];
                if (item.events && item.events.length > 0) {
                  item.events.forEach((ele) => {
                    if (ele.outputData && ele.outputData.length > 0) {
                      ele.outputData.forEach((val) => {
                        if (ele.eventName === value[1]) {
                          tempList.push({
                            label: val.paramName,
                            value: val.paramName,
                          });
                        }
                      });
                    }
                  });
                }
                this.conditionList[index].eventParamsList = tempList;
              }
            });
          });
          this.conditionList[index].sourceType = "event";
          break;
        case "status":
          this.conditionList[index].ruleList[0].operator = "==";
          this.conditionList[index].sourceType = "status";
          break;
        default:
          this.conditionList[index].sourceType = "";
      }
    },
    // 规则类型变化，修改数据源下拉框的值
    buildDataResourceList(ruleType) {
      // 清空选择的数据源，修改resourceOptions
      let tempConditionList = deepClone(this.conditionList);
      tempConditionList.forEach((item) => {
        item.productOrDevice = null;
        item.resouceOrModule = null;
        item.dataResourceOptions = {};
        item.sourceType = null;
        item.resourceOptions = DataResourceTotalList[ruleType];
      });
      this.conditionList = tempConditionList;
    },
    getTslHandler(productKey, index) {
      findTsl({ productKey }).then((res) => {
        this.loadDataResourceDatas(res, index);
      });
    },
    loadDataResourceDatas(res, index) {
      const treeDatas = this.buildTreeDatas(res.modules);
      console.log("treeDatas", treeDatas);
      // let tempList = deepClone(this.conditionList[index].dataResourceOptions);
      // tempList.forEach((item) => {
      //   if (treeDatas[item.value]) {
      //     item["children"] = treeDatas[item.value];
      //   }
      // });
      // console.log("tempList", tempList);
      this.conditionList[index].dataResourceOptions = treeDatas;
    },
    buildTreeDatas(modules) {
      // 构建属性、事件的children数据
      let resultObj = { property: [], event: [] };
      if (modules && modules.length > 0) {
        modules.forEach((item, index) => {
          // 创建模块数据
          resultObj.property.push({
            label: item.moduleName,
            value: item.moduleName,
            children: [],
          });
          // 如果tsl中有属性数据，则往
          if (item.properties && item.properties.length > 0) {
            item.properties.forEach((ele) => {
              resultObj.property.forEach((v, ii) => {
                // 根据模块去添加属性
                if (index === ii) {
                  v.children.push({
                    label: ele.description || ele.propertyName,
                    value: ele.propertyName,
                  });
                }
              });
            });
          }
          resultObj.event.push({
            label: item.moduleName,
            value: item.moduleName,
            children: [],
          });
          if (item.events && item.events.length > 0) {
            item.events.forEach((ele) => {
              resultObj.event.forEach((v, ii) => {
                // 根据模块去添加属性
                if (index === ii) {
                  v.children.push({
                    label: ele.description || ele.eventName,
                    value: ele.eventName,
                  });
                }
              });
            });
          }
        });
      }
      return resultObj;
    },
    deleteCondition(index) {
      this.conditionList.splice(index, 1);
    },
    deleteOperationRule(index, i) {
      this.conditionList[index].ruleList.splice(i, 1);
    },
    // 添加规则
    addOperationRule(index) {
      console.log("index", index);
      this.conditionList[index].ruleList.push(deepClone(EmptyOperationLine));
    },
    addCondition() {
      // EmptyLine.dataResourceOptions =
      //   DataResourceTotalList[this.currentRuleType];
      EmptyLine.resourceOptions = DataResourceTotalList[this.currentRuleType];
      let currentLine = deepClone(EmptyLine);
      console.log("currentLine", currentLine);
      // currentLine.time = this.logic === "OR" ? 120 : 0;
      this.conditionList.push(currentLine);
    },
  },
};
</script>

<style scoped lang="less">
.normal-condition-component {
  .active-time-line {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
    .title-label {
      margin-right: 10px;
    }
    .ml10 {
      margin-left: 10px;
    }
    .top3 {
      position: relative;
      top: 3px;
    }
  }
  .rule-main-container {
    font-size: 14px;
    display: flex;
    margin-bottom: 20px;
    .title-line {
      height: 36px;
      line-height: 36px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      margin-right: 12px;
      &::before {
        content: "";
        width: 4px;
        height: 24px;
        display: inline-block;
        background-color: #0486fe;
        margin-right: 8px;
      }
    }
    .detail-line {
      flex: 1;
      .detail-title {
        .title-after {
          margin-left: 12px;
        }
      }
      .rule-detail-line {
        .topic-line {
          padding-left: 398px;
        }
        .rule-line {
          margin: 20px 0;
          .index-span {
            background: #f8f9f9;
            border-radius: 4px;
            color: #606266;
            padding: 4px 11px;
            width: 60px;
            display: inline-block;
          }
          .add-rule-button {
            margin-left: 12px;
            cursor: pointer;
            color: #0486fe;
          }
          .delete-rule-button {
            margin-left: 12px;
            background: #f6f6f6;
            cursor: pointer;
            color: #2a4158;
            display: inline-block;
            width: 36px;
            height: 36px;
            text-align: center;
            padding: 0;
            &:hover {
              border-color: #dcdfe6;
            }
          }
          .delete-condition-button {
            margin-left: 64px;
            cursor: pointer;
            color: #e47470;
          }
          .split-span {
            margin: 0 12px;
          }
          .w360 {
            width: 360px;
          }
          .w175 {
            width: 175px;
          }
          .w120 {
            width: 120px;
          }
          .w230 {
            width: 230px;
          }
          .w100 {
            width: 100px;
          }
          .w194 {
            width: 194px;
          }
          .w211 {
            width: 210px;
          }
          .w232 {
            width: 232px;
          }
          .mr8 {
            margin-right: 8px;
          }
          .ml10 {
            margin-left: 10px;
          }
          .ml8 {
            margin-left: 8px;
          }
        }
        .second-line {
          margin-left: 60px;
        }
        .last-line {
          padding-left: 430px;
        }
        .event-last-line {
          padding-left: 212px;
        }
      }
    }
    .button-container {
      margin: 40px 0 20px;
    }
    .el-input + .el-input {
      margin-left: 8px;
    }
    ::v-deep .el-form-item {
      display: inline-block;
    }
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-icon-plus:hover {
    border-color: #1a4cec !important;
  }
  ::v-deep
    .el-checkbox-button.is-disabled.is-checked
    .el-checkbox-button__inner {
    background-color: #f2f6fc;
  }
}
</style>