<template>
  <div class="message-action-comp">
    <el-form :model="form" ref="actionForm">
      <AceEditor
        ref="aceEditorRef"
        key="customizeJson"
        v-model="form.message"
        @init="editorInit"
        lang="text"
        theme="chrome"
        style="border: 1px solid #ebecec"
        height="200"
        width="719"
      ></AceEditor>
    </el-form>
  </div>
</template>

<script>
import AceEditor from "vue2-ace-editor";
import { actionComponentMixins } from "@/mixins/actionComponentMixins.js";
export default {
  mixins: [actionComponentMixins],
  components: {
    AceEditor,
  },
  props: {
    currentState: {
      type: String,
      required: true,
    },
  },
  watch: {
    currentState: {
      handler(val) {
        val && this.setEditorState(val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      form: {
        message: "",
      },
    };
  },
  methods: {
    editorInit(thingEditor) {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/javascript"); //language
      require("brace/theme/chrome");
      thingEditor.setShowPrintMargin(false);
      thingEditor.setReadOnly(this.currentState === "view");
    },
    setEditorState(state) {
      const curEditor = this.$refs.aceEditorRef;
      curEditor && curEditor.editor.setReadOnly(state === "view");
    },
  },
};
</script>

<style scoped lang="less">
.message-action-comp {
}
</style>