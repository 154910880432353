<template>
  <div class="rule-action-component">
    <div class="title-line">则执行</div>
    <div>
      <div
        class="detail-line"
        v-for="(item, index) in actionList"
        :key="item.key"
      >
        <div class="detail-title">
          <span class="action-index">
            {{ "动作" + (index + 1) }}
          </span>
          <el-radio-group
            :disabled="currentState === 'view'"
            v-model="item.currentActionType"
            @change="(value) => actionTypeChange(value, index)"
          >
            <el-radio-button
              v-for="val in actionTypeList"
              :disabled="
                alarmIndex !== null &&
                alarmIndex !== index &&
                val.value === 'alarm'
              "
              :key="val.value"
              :label="val.value"
              >{{ val.label }}</el-radio-button
            >
          </el-radio-group>
          <el-button
            :disabled="currentState === 'view'"
            v-if="actionList.length > 1 && source !== 'dataset'"
            type="danger"
            icon="el-icon-delete"
            class="delete-button"
            plain
            @click="deleteAction(index)"
            >删除动作</el-button
          >
        </div>
        <div class="component-container">
          <component
            ref="actionTypeRef"
            :is="item.currentActionType"
            :currentActionType="item.currentActionType"
            :consumerGroupList="consumerGroupList"
            :currentState="currentState"
            :extendConfig="actionList[index].extendConfig"
          />
        </div>
      </div>
      <div class="button-container">
        <el-button
          type="primary"
          plain
          @click="addAction"
          :disabled="currentState === 'view'"
          v-if="source !== 'dataset'"
          >添加动作</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import { deepClone } from "@/utils/utils.js";
import service from "./actionTypeComponent/service.vue";
import property from "./actionTypeComponent/property.vue";
import message from "./actionTypeComponent/message.vue";
import alarm from "./actionTypeComponent/alarm.vue";
import deviceCall from "./actionTypeComponent/deviceCall.vue";
import dataFlow from "./actionTypeComponent/dataFlow.vue";

import { findConsumerGroupList } from "@/api/ruge/vlink/consumerGroup/consumerGroup";
const ActionTypeOfRuleType = {
  linkage: [
    { label: "属性设置", value: "property" },
    { label: "调用服务", value: "service" },
    { label: "消息", value: "message" },
  ],
  alarm: [
    { label: "告警", value: "alarm" },
    { label: "属性设置", value: "property" },
    { label: "调用服务", value: "service" },
    { label: "消息", value: "message" },
  ],
  schedule: [
    { label: "属性设置", value: "property" },
    { label: "调用服务", value: "service" },
    { label: "消息", value: "message" },
  ],
  deviceCall: [{ label: "设备通信", value: "deviceCall" }],
  serverSub: [
    // { label: "服务端订阅", value: "serverSub" },
    // { label: "数据流转到Station", value: "stationSub" },
    { label: "数据流转", value: "dataFlow" },
  ],
};
export default {
  components: {
    service,
    property,
    message,
    alarm,
    deviceCall,
    dataFlow,
  },
  props: {
    currentRuleType: {
      type: String,
      required: true,
    },
    currentRowData: {
      type: Object,
      required: false,
    },
    currentState: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default() {
        return "url";
      },
    },
  },
  watch: {
    currentRuleType: {
      handler(ruleType) {
        console.log("ruleType", ruleType);
        if (ruleType === "serverSub" && this.consumerGroupList.length === 0) {
          this.getConsumerListHandler();
        }
        this.actionTypeList = ActionTypeOfRuleType[ruleType];
        this.actionList = [
          {
            currentActionType: this.actionTypeList[0].value,
          },
        ];
      },
      immediate: true,
    },
    currentRowData: {
      handler(val) {
        if (!val) return;
        this.loadActionDatas(val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      // 消费组List
      consumerGroupList: [],
      actionTypeList: ActionTypeOfRuleType["linkage"],
      currentActionType: "service",
      actionList: [
        {
          currentActionType: "service",
          key: new Date().getTime(),
        },
      ],
      alarmIndex: null,
    };
  },
  methods: {
    loadActionDatas(datas) {
      const { actions } = JSON.parse(datas.ruleConfig);
      let tempList = [];
      actions.forEach((item) => {
        // stationSub和serverSub类型的合并到dataFlow，回显的时候需要特殊处理下。
        tempList.push({
          extendConfig: JSON.parse(item.extendConfig),
          currentActionType:
            item.type === "stationSub" || item.type === "serverSub"
              ? "dataFlow"
              : item.type,
        });
      });
      this.actionList = tempList;
      console.log("this.actionList", this.actionList);
    },
    checkValidAndEmitDatas() {
      // 提交每一个动作类型的表单验证，并return验证结果
      const refList = this.$refs.actionTypeRef;
      let requestList = [];
      refList.forEach((item) => {
        requestList.push(item.checkAndGetDatas());
      });
      return Promise.all(requestList).then((res) => {
        return res;
      });
    },
    getConsumerListHandler() {
      findConsumerGroupList().then((res) => {
        this.consumerGroupList = res;
      });
    },
    addAction() {
      this.actionList.push({
        currentActionType: ActionTypeOfRuleType[this.currentRuleType][0].value,
        key: new Date().getTime(),
      });
    },
    actionTypeChange(type, index) {
      // 如果当前切换的是告警类型，则设置alarmIndex = index
      if (type === "alarm") {
        this.alarmIndex = index;
      }
      this.$nextTick(() => {
        // 如果取消了告警的选择，恢复所有告警可选
        const alarmSelectNum = this.actionList.filter(
          (item) => item.currentActionType === "alarm"
        ).length;
        if (alarmSelectNum === 0) {
          this.alarmIndex = null;
        }
      });
      this.currentActionType = type;
    },
    deleteAction(index) {
      this.actionList.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.rule-action-component {
  font-size: 14px;
  display: flex;
  .title-line {
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin-right: 12px;
    &::before {
      content: "";
      width: 4px;
      height: 24px;
      display: inline-block;
      background-color: #0486fe;
      margin-right: 8px;
    }
  }
  .detail-line {
    flex: 1;
    .detail-title {
      display: flex;
      align-items: center;
      .action-index {
        background: #f8f9f9;
        border-radius: 4px;
        color: #606266;
        padding: 4px 11px;
        width: 60px;
        font-size: 14px;
        margin-right: 38px;
      }
      .delete-button {
        margin-left: 12px;
      }
    }
  }
  .component-container {
    padding: 20px 0;
    margin-left: 98px;
  }
  .button-container {
    margin: 20px 0;
  }
  ::v-deep .el-form-item {
    display: inline-block;
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
}
</style>