var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule-basic-form" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.ruleLoading,
              expression: "ruleLoading",
            },
          ],
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.rule.ruleName"),
                        prop: "ruleName",
                        rules: [
                          {
                            required: _vm.currentState === "add",
                            message: _vm.$t("validate.required"),
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _vm.currentState === "add"
                        ? _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("commons.pleaseInput"),
                              maxlength: "30",
                            },
                            model: {
                              value: _vm.ruleForm.ruleName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "ruleName", $$v)
                              },
                              expression: "ruleForm.ruleName",
                            },
                          })
                        : _c("span", [
                            _vm.nameEdit
                              ? _c(
                                  "span",
                                  [
                                    _c("el-input", {
                                      ref: "nameInputRef",
                                      on: {
                                        blur: function ($event) {
                                          return _vm.fieldsUpdateHandler(
                                            "ruleName"
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return ((event) =>
                                            event.target.blur()).apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.ruleName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "ruleName",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.ruleName",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.ruleForm.ruleName || "-") +
                                        "\n              "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "修改规则名称",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm.source !== "dataset"
                                          ? _c("i", {
                                              staticClass: "el-icon-edit",
                                              on: { click: _vm.nameEditEnable },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.rule.ruleScope"),
                        prop: "ruleScope",
                        rules: [
                          {
                            required: _vm.currentState === "add",
                            message: _vm.$t("validate.required"),
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _vm.currentState === "add"
                        ? _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.ruleScope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "ruleScope", $$v)
                                },
                                expression: "ruleForm.ruleScope",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "CLOUD", border: "" } },
                                [_vm._v(_vm._s(_vm.$t("vlink.rule.cloud")))]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "EDGE", border: "" } },
                                [_vm._v(_vm._s(_vm.$t("vlink.rule.edge")))]
                              ),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.ruleForm.ruleScope
                                    ? _vm.ruleForm.ruleScope === "CLOUD"
                                      ? _vm.$t("vlink.rule.cloud")
                                      : _vm.$t("vlink.rule.edge")
                                    : "-"
                                ) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.rule.ruleType"),
                        prop: "ruleType",
                        rules: [
                          {
                            required: _vm.currentState === "add",
                            message: _vm.$t("validate.required"),
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _vm.currentState === "add"
                        ? _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { clearable: "" },
                              on: { change: _vm.ruleTypeChange },
                              model: {
                                value: _vm.ruleForm.ruleType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "ruleType", $$v)
                                },
                                expression: "ruleForm.ruleType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  value: "linkage",
                                  label: _vm.$t("vlink.rule.linkage"),
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: "alarm",
                                  label: _vm.$t("vlink.rule.alarmRule"),
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: "schedule",
                                  label: _vm.$t("vlink.rule.schedule"),
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  value: "deviceCall",
                                  label: _vm.$t("vlink.rule.deviceCall"),
                                },
                              }),
                              _vm.ruleForm.ruleScope == "CLOUD"
                                ? [
                                    _c("el-option", {
                                      attrs: {
                                        value: "serverSub",
                                        label: "数据流转",
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.renderRuleScope(_vm.ruleForm.ruleType)
                                ) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("vlink.rule.description"),
                        prop: "description",
                      },
                    },
                    [
                      _vm.currentState === "add"
                        ? _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("commons.pleaseInput"),
                              maxlength: "150",
                            },
                            model: {
                              value: _vm.ruleForm.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "description", $$v)
                              },
                              expression: "ruleForm.description",
                            },
                          })
                        : _c("span", [
                            _vm.descriptionEdit
                              ? _c(
                                  "span",
                                  [
                                    _c("el-input", {
                                      ref: "descInputRef",
                                      attrs: { maxlength: "1000" },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.fieldsUpdateHandler(
                                            "description"
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return ((event) =>
                                            event.target.blur()).apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.ruleForm.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.description",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  {
                                    attrs: { title: _vm.ruleForm.description },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.ruleForm.description || "-"
                                        ) +
                                        "\n              "
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "修改规则描述",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm.source !== "dataset"
                                          ? _c("i", {
                                              staticClass: "el-icon-edit",
                                              on: { click: _vm.descEditEnable },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.currentState !== "add"
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "创建时间", prop: "creationDate" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.ruleForm.creationDate,
                                    "YYYY-MM-DD HH:mm:ss"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否启用", prop: "ruleStatus" } },
                          [
                            _c("el-switch", {
                              attrs: {
                                disabled: _vm.source === "dataset",
                                "active-value": "ENABLE",
                                "inactive-value": "DISABLE",
                                "active-color": "#13ce66",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.updateRuleStatusHandler(
                                    _vm.ruleForm.ruleStatus
                                  )
                                },
                              },
                              model: {
                                value: _vm.ruleForm.ruleStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "ruleStatus", $$v)
                                },
                                expression: "ruleForm.ruleStatus",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "规则标识码", prop: "ruleKey" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.ruleForm.ruleKey || "-") +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }