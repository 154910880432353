var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "data-flow-comp" },
    [
      _c(
        "el-form",
        { ref: "actionForm", attrs: { model: _vm.form } },
        [
          _c("span", { staticClass: "select-label" }, [_vm._v(" 流转方式： ")]),
          _c(
            "el-select",
            {
              attrs: {
                disabled: _vm.currentState === "view",
                placeholder: "流转方式",
              },
              on: { change: _vm.dataFlowChange },
              model: {
                value: _vm.form.dataFlowType,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "dataFlowType", $$v)
                },
                expression: "form.dataFlowType",
              },
            },
            [
              _c("el-option", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.stationSelected,
                    expression: "!stationSelected",
                  },
                ],
                attrs: { value: "stationSub", label: "数据流转到Rdata" },
              }),
              _c("el-option", {
                attrs: { value: "serverSub", label: "服务端订阅" },
              }),
            ],
            1
          ),
          _vm.form.dataFlowType === "serverSub"
            ? _c(
                "span",
                { staticClass: "consumer-container" },
                [
                  _c("span", { staticClass: "select-label" }, [
                    _vm._v(" 消费组名称： "),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "consumerGroupName",
                        rules: _vm.form.dataFlowType === "serverSub" && [
                          {
                            required: true,
                            message: "请选择消费组",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.currentState === "view",
                            placeholder: "消费组名称",
                          },
                          on: { change: _vm.consumerGroupChange },
                          model: {
                            value: _vm.form.consumerGroupName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "consumerGroupName", $$v)
                            },
                            expression: "form.consumerGroupName",
                          },
                        },
                        _vm._l(_vm.consumerGroupList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              value: item.consumerGroupName,
                              label: item.consumerGroupName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }