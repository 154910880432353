<template>
  <div class="schedule-rule-comp">
    <div class="title-line">时间表</div>
    <div class="detail-line">
      <div class="detail-title">
        <el-radio-group
          v-model="scheduleType"
          :disabled="currentState === 'view'"
        >
          <el-radio-button label="timeSchedule">时间表选择器</el-radio-button>
          <el-radio-button label="cronSchedule">Cron表达式</el-radio-button>
        </el-radio-group>
      </div>
      <div class="component-container">
        <component
          ref="scheduleRef"
          :is="scheduleType"
          :currentState="currentState"
          :extendConfig="extendConfig"
          :type="scheduleType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import timeSchedule from "./scheduleComponent/time.vue";
import cronSchedule from "./scheduleComponent/cron.vue";
export default {
  components: {
    timeSchedule,
    cronSchedule,
  },
  props: {
    currentRowData: {
      type: Object,
      required: false,
    },
    currentState: {
      type: String,
      required: true,
    },
  },
  watch: {
    currentRowData: {
      handler(val) {
        val && this.loadScheduleDatas(val);
      },
      immediate: true,
    },
  },
  data() {
    return {
      scheduleType: "timeSchedule",
      extendConfig: {},
    };
  },
  methods: {
    loadScheduleDatas(datas) {
      const { conditionGroup } = JSON.parse(datas.ruleConfig);
      console.log("loadScheduleDatas", conditionGroup);
      this.scheduleType = conditionGroup.scheduleType;
      this.extendConfig = JSON.parse(conditionGroup.extendConfig);
      console.log("this.extendConfig", this.extendConfig);
    },
    checkValidAndEmitDatas() {
      return this.$refs["scheduleRef"].checkValidAndEmitDatas(
        this.scheduleType
      );
    },
  },
};
</script>

<style lang="less" scoped>
.schedule-rule-comp {
  font-size: 14px;
  display: flex;
  margin-bottom: 20px;
  .title-line {
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin-right: 12px;
    &::before {
      content: "";
      width: 4px;
      height: 24px;
      display: inline-block;
      background-color: #0486fe;
      margin-right: 8px;
    }
  }
  .detail-line {
    flex: 1;
  }
  .component-container {
  }
}
</style>