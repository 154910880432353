var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "rule-detail-component",
        _vm.source !== "dataset" && "app-container",
      ],
    },
    [
      _c("div", { staticClass: "card-container" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "form-container" },
          [
            _c("ruleBasicForm", {
              ref: "basicRef",
              attrs: {
                source: _vm.source,
                currentState: _vm.currentState,
                currentRowData: _vm.currentRowData,
                ruleLoading: _vm.ruleLoading,
              },
              on: {
                ruleTypeChange: _vm.ruleTypeChange,
                updateField: _vm.fieldsUpdate,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "card-container" }, [
        _vm._m(1),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.ruleLoading,
                expression: "ruleLoading",
              },
            ],
            staticClass: "component-container",
          },
          [
            _c(
              "div",
              { staticClass: "condition-component" },
              [
                _c(_vm.currentConditionType, {
                  ref: "conditionRef",
                  tag: "component",
                  attrs: {
                    currentRuleType: _vm.currentRuleType,
                    currentRowData: _vm.currentRowData,
                    currentState: _vm.currentState,
                    source: _vm.source,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "action-component" },
              [
                _c("actionComponent", {
                  ref: "actionsRef",
                  attrs: {
                    currentRuleType: _vm.currentRuleType,
                    currentRowData: _vm.currentRowData,
                    currentState: _vm.currentState,
                    source: _vm.source,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.source !== "dataset"
        ? _c(
            "div",
            { staticClass: "button-container" },
            [
              _vm.currentState !== "view"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.saveHandler },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _vm.currentState !== "view"
                ? _c("el-button", { on: { click: _vm.cancelHandler } }, [
                    _vm._v("取消"),
                  ])
                : _vm._e(),
              _vm.currentState === "view"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.ruleLoading,
                          expression: "ruleLoading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.changeToEdit },
                    },
                    [_vm._v("编辑")]
                  )
                : _vm._e(),
              _vm.currentState !== "add"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        disabled:
                          (_vm.currentRowData &&
                            _vm.currentRowData.ruleStatus === "ENABLE") ||
                          _vm.currentState === "view",
                      },
                      on: { click: _vm.deleteHandler },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("span", { staticClass: "title-label" }, [_vm._v(" 基础信息 ")]),
      _c("span", { staticClass: "title-tips" }, [
        _c("i", { staticClass: "el-icon-info" }),
        _vm._v("\n        用户配置的规则描述\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("span", { staticClass: "title-label" }, [_vm._v(" 规则配置 ")]),
      _c("span", { staticClass: "title-tips" }, [
        _c("i", { staticClass: "el-icon-info" }),
        _vm._v("\n        用户自由配置的规则条件和执行操作\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }