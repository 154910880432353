<template>
  <div class="rule-config-service">
    <div class="detail-line">
      <el-form :model="form" ref="actionForm" :rules="rules">
        <span class="split-span-first"> 调用 </span>
        <el-form-item prop="product">
          <el-select
            :disabled="currentState === 'view'"
            v-model="form.product"
            placeholder="产品"
            filterable
            class="w175"
            @change="(value) => productChange(value)"
          >
            <el-option
              v-for="item in productOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="device">
          <el-select
            :disabled="currentState === 'view'"
            v-model="form.device"
            placeholder="设备"
            class="w175 ml10"
            filterable
            remote
            clearable
            :remote-method="(value) => deviceRemoteMethod(value)"
            :loading="loading"
          >
            <el-option
              v-for="item in deviceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <span class="split-span"> 中的 </span>
        <el-form-item prop="moduleOrService">
          <el-cascader
            :disabled="currentState === 'view'"
            class="w306"
            placeholder="模块/服务"
            v-model="form.moduleOrService"
            @change="moduleOrServiceChange"
            :options="form.moduleServiceOptions"
          ></el-cascader>
        </el-form-item>
      </el-form>
    </div>
    <el-row class="detail-line" :gutter="48" style="width: 790px">
      <el-col
        class="params-line"
        :span="12"
        v-for="(item, index) in form.paramList"
        :key="index"
      >
        <div class="params-title">
          {{ "下发参数 " + item.paramName }}
        </div>
        <div class="params-detail">
          <el-input
            :disabled="currentState === 'view'"
            v-model="item.value"
            placeholder="请输入设置值"
            class="w330"
          ></el-input>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getProductList,
  findTsl,
  findCustomTopicList,
} from "@/api/ruge/vlink/product/product";
import { findDeviceList, findDevicePage } from "@/api/ruge/vlink/device/device";
import { actionComponentMixins } from "@/mixins/actionComponentMixins.js";
export default {
  mixins: [actionComponentMixins],
  props: {
    currentState: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        product: "",
        device: "",
        productOrDevice: null,
        moduleOrService: null,
        moduleServiceOptions: [],
        paramList: [],
        paramObj: {},
      },
      deviceOptions: [],
      productOptions: [],
      loading: false,
      deviceQueryParams: {
        current: 1,
        rowCount: 50,
        description: null,
        productKey: null,
      },
      rules: {
        product: [
          {
            required: true,
            message: "请选择产品",
            trigger: ["blur", "change"],
          },
        ],
        device: [
          {
            required: true,
            message: "请选择设备",
            trigger: ["blur", "change"],
          },
        ],
        moduleOrService: [
          {
            required: true,
            message: "请选择模块/服务",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.getProductListHandler();
  },
  methods: {
    getProductListHandler() {
      getProductList().then((productList) => {
        const nodes = productList.map((item) => ({
          value: item.productKey,
          label: item.productName,
        }));
        nodes.sort((item1, item2) => {
          return item1.label.localeCompare(item2.label, "zh-CN");
        });
        this.productOptions = nodes;
      });
    },
    productChange(value) {
      // 清空一选择的设备、属性
      this.form.device = "";
      this.form.moduleOrService = null;
      this.deviceOptions = [];
      this.form.moduleServiceOptions = [];
      // this.form.paramList = [];
      // 根据产品获取设备
      this.deviceRemoteMethod("");
      // 根据产品获取物模型
      this.getTslByProduct(value);
    },
    deviceRemoteMethod(query, product) {
      if (product || this.form.product) {
        this.loading = true;
        let params = this.deviceQueryParams;
        params.description = query;
        params.productKey = product || this.form.product || "";
        findDevicePage(params)
          .then((res) => {
            const currentDevices = res.rows.map((item) => {
              return {
                value: item.deviceName,
                label: item.description
                  ? `${item.description} (${item.deviceName})`
                  : `${item.deviceName} (${item.deviceName})`,
              };
            });
            this.deviceOptions = currentDevices;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deviceOptions = [];
      }
    },
    getTslByProduct(productKey) {
      findTsl({ productKey }).then((res) => {
        if (res && res.modules && res.modules.length > 0) {
          this.loadTslDatas(res.modules);
        } else {
          this.form.moduleOrService = null;
          this.$nextTick(() => {
            this.form.moduleServiceOptions = [];
            // this.form.paramList = [];
          });
        }
      });
    },
    loadTslDatas(modules) {
      let tempList = [];
      this.form.paramObj = {};
      // this.form.paramList = [];
      modules.forEach((item, index) => {
        let tempObj = {
          value: item.moduleName,
          label: item.moduleName,
          children: [],
        };
        if (item.services && item.services.length > 0) {
          item.services.forEach((val, i) => {
            tempObj.children.push({
              value: val.serviceName,
              label: val.serviceName,
            });
            if (val.inputData && val.inputData.length > 0) {
              this.form.paramObj[val.serviceName] = val.inputData;
            }
          });
        }
        tempList.push(tempObj);
      });
      console.log("tempList", tempList);
      this.form.moduleServiceOptions = tempList;
    },
    moduleOrServiceChange(value) {
      const ServiceName = value[1];
      this.form.paramList = this.form.paramObj[ServiceName];
    },
  },
};
</script>

<style lang="less" scoped>
.rule-config-service {
  .detail-line {
    margin-bottom: 20px;
    .params-title {
      opacity: 0.7;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
    }
    .params-line {
      margin-bottom: 20px;
    }
  }
  .split-span {
    margin: 0 12px;
  }
  .split-span-first {
    margin-right: 12px;
  }
  .w306 {
    width: 306px;
  }
  .w330 {
    width: 330px;
  }
  .w175 {
    width: 175px;
  }
  .ml10 {
    margin-left: 10px;
  }
}
</style>