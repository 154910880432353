/**
 * actionComponent下的action分类组件
 *
 * 注意：
 *  1.组件中的data中必须有form，存放表单数据
 *  2.验证表单的form的ref名称必须是：actionForm
 *
 */
export const actionComponentMixins = {
  props: {
    currentActionType: {
      type: String,
      required: true,
    },
    extendConfig: {
      type: Object,
      required: false,
    },
  },
  watch: {
    extendConfig: {
      handler(val) {
        if (val) {
          console.log('this.extendConfig', this.extendConfig);
          this.form = this.extendConfig;
          if (this.extendConfig.currentActionType === 'deviceCall') {
            this.$set(
              this.form,
              'product',
              this.extendConfig.productOrDevice[0]
            );
            this.$set(
              this.form,
              'device',
              this.extendConfig.productOrDevice[1]
            );
            this.getTopicByProduct(this.form.product);
            this.deviceRemoteMethod('', this.form.product);
          }
          if (
            ['property', 'service'].includes(
              this.extendConfig.currentActionType
            )
          ) {
            this.$set(
              this.form,
              'product',
              this.extendConfig.productOrDevice[0]
            );
            this.$set(
              this.form,
              'device',
              this.extendConfig.productOrDevice[1]
            );
            this.deviceRemoteMethod('', this.form.product);
            this.getTslByProduct(this.form.product);
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.form.currentActionType = this.currentActionType;
  },
  methods: {
    checkAndGetDatas() {
      return new Promise((resolve) => {
        this.$refs.actionForm.validate((valid) => {
          if (valid) {
            this.form.productOrDevice = [];
            if (this.form.product) {
              this.form.productOrDevice.push(this.form.product);
            }
            if (this.form.device) {
              this.form.productOrDevice.push(this.form.device);
            }
            resolve(this.form);
          }
        });
      }).catch(() => {});
    },
  },
};
