<template>
  <div class="schedule-cron-comp">
    <el-form :model="form" ref="scheduleForm" :rules="rules">
      <div class="detail-line list-line">
        <span class="detail-label">日期</span>
        <div>
          <el-radio-group
            v-model="form.limitOrNot"
            @change="limitChange"
            :disabled="currentState === 'view'"
          >
            <el-radio-button label="noLimit">不限日期</el-radio-button>
            <el-radio-button label="limit">具体日期</el-radio-button>
          </el-radio-group>
          <div v-if="form.limitOrNot === 'limit'">
            <div
              v-for="(item, index) in form.dataRangeList"
              :key="index"
              class="content-detail-lines"
            >
              <el-date-picker
                :disabled="currentState === 'view'"
                v-model="item.dateRange"
                type="datetimerange"
                class="w400"
                :range-separator="$t('commons.rangeSeparator')"
                :start-placeholder="$t('commons.startDate')"
                :end-placeholder="$t('commons.endDate')"
              >
              </el-date-picker>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除生效日期"
                placement="bottom"
              >
                <el-button
                  :disabled="currentState === 'view'"
                  v-show="form.dataRangeList.length > 1"
                  @click="deleteDate(index)"
                  class="date-button"
                  icon="el-icon-delete"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="添加生效日期"
                placement="bottom"
              >
                <el-button
                  :disabled="currentState === 'view'"
                  @click="addDate"
                  class="date-button"
                  icon="el-icon-plus"
                ></el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-line list-line">
        <span class="detail-label">Cron表达式</span>
        <div class="cron-line">
          <div class="cron-card">
            <el-form-item prop="second">
              <el-input
                :disabled="currentState === 'view'"
                class="w80"
                v-model="form.second"
              ></el-input>
            </el-form-item>
            <span class="cron-label">秒</span>
          </div>
          <div class="cron-card">
            <el-form-item prop="minute">
              <el-input
                :disabled="currentState === 'view'"
                class="w80"
                v-model="form.minute"
              ></el-input>
            </el-form-item>
            <span class="cron-label">分</span>
          </div>
          <div class="cron-card">
            <el-form-item prop="hour">
              <el-input
                :disabled="currentState === 'view'"
                class="w80"
                v-model="form.hour"
              ></el-input>
            </el-form-item>
            <span class="cron-label">时</span>
          </div>
          <div class="cron-card">
            <el-form-item prop="day">
              <el-input
                :disabled="currentState === 'view'"
                class="w80"
                v-model="form.day"
              ></el-input>
            </el-form-item>
            <span class="cron-label">日</span>
          </div>
          <div class="cron-card">
            <el-form-item prop="month">
              <el-input
                :disabled="currentState === 'view'"
                class="w80"
                v-model="form.month"
              ></el-input>
            </el-form-item>
            <span class="cron-label">月</span>
          </div>
          <div class="cron-card">
            <el-form-item prop="week">
              <el-input
                :disabled="currentState === 'view'"
                class="w80"
                v-model="form.week"
              ></el-input>
            </el-form-item>
            <span class="cron-label">周</span>
          </div>
          <div class="cron-card">
            <el-form-item prop="year">
              <el-input
                :disabled="currentState === 'view'"
                class="w80"
                v-model="form.year"
              ></el-input>
            </el-form-item>
            <span class="cron-label">年</span>
          </div>
        </div>
      </div>
      <div class="detail-line list-line">
        <span class="detail-label">执行时间</span>
        <div v-if="showResult">
          <div
            class="execution-line"
            v-for="(item, index) in resultList"
            :key="index"
          >
            <span class="index-span">{{ index + 1 }}</span>
            <span class="execution-detail">{{ item }}</span>
          </div>
        </div>
        <div class="execution-line" v-else>
          <span class="error-line">Cron表达式有误，无法计算执行时间！</span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  cronValidate,
  cronSecondValid,
  cronMinuteValid,
  cronHourValid,
  cronDayValid,
  cronMonthValid,
  cronWeekValid,
  cronYearValid,
} from "@/utils/utils.js";
import { cronResultMixins } from "@/mixins/cronResultMixins.js";
import moment from "moment";
export default {
  name: "scheduleCron",
  mixins: [cronResultMixins],
  props: {
    currentState: {
      type: String,
      required: true,
    },
    extendConfig: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showResult: false,
      form: {
        limitOrNot: "noLimit",
        dataRangeList: [
          {
            dateRange: null,
          },
        ],
        second: "0",
        minute: "30",
        hour: "8",
        day: "*",
        month: "*",
        week: "?",
        year: "*",
        cron: "0 30 8 * * ? *",
      },
      resultList: [],
      rules: {
        second: [
          { required: true, validator: cronSecondValid, trigger: "change" },
        ],
        minute: [
          { required: true, validator: cronMinuteValid, trigger: "change" },
        ],
        hour: [{ required: true, validator: cronHourValid, trigger: "change" }],
        day: [{ required: true, validator: cronDayValid, trigger: "change" }],
        month: [
          { required: true, validator: cronMonthValid, trigger: "change" },
        ],
        week: [{ required: true, validator: cronWeekValid, trigger: "change" }],
        year: [{ required: true, validator: cronYearValid, trigger: "change" }],
      },
    };
  },
  watch: {
    form: {
      handler() {
        const { second, minute, hour, day, month, week, year } = this.form;
        this.form.cron = `${second} ${minute} ${hour} ${day} ${month} ${week} ${year}`;
        // if (this.form.limitOrNot === "noLimit") {
        //   this.form.dataRangeList = [
        //     {
        //       dateRange: null,
        //     },
        //   ];
        // }
        this.$nextTick(() => {
          console.log("this.form.limitOrNot", this.form.limitOrNot);
          console.log("this.form.limitOrNot", this.form.dataRangeList);
          this.checkAndGetResult();
        });
      },
      deep: true,
    },
    extendConfig: {
      handler(val) {
        if (val && this.currentState !== "add") {
          console.log("cron====>", val);
          this.form = val;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.checkAndGetResult();
  },
  methods: {
    limitChange() {
      this.form.dataRangeList = [
        {
          dateRange: null,
        },
      ];
    },
    deleteDate(index) {
      this.form.dataRangeList.splice(index, 1);
    },
    checkValidAndEmitDatas(scheduleType) {
      return new Promise((resolve) => {
        this.$refs.scheduleForm.validate((valid) => {
          if (valid) {
            let resultObj = {
              triggerCron: this.form.cron,
              conditions:
                this.form.limitOrNot === "noLimit"
                  ? [{ enableDate: null }]
                  : this.form.dataRangeList.map((item) => {
                      return {
                        enableDate: item.dateRange,
                      };
                    }),
              scheduleType,
              extendConfig: JSON.stringify(this.form),
            };
            resolve(resultObj);
          }
        });
      });
    },
    checkAndGetResult() {
      const { cron } = this.form;
      // 校验表达式是否合法
      if (!cronValidate(cron)) {
        // this.$message.warning("cron表达式格式不正确");
        this.showResult = false;
        return;
      }
      // 获取当前cron表达式的最近5次执行时间
      setTimeout(() => {
        console.log("xxx-start", new Date().getTime());
        this.expressionChange(cron);
        this.showResult = true;
        console.log("xxx-end", new Date().getTime());
      }, 500);
      this.$nextTick(() => {});
    },
    addDate() {
      this.form.dataRangeList.push({
        dateRange: null,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.schedule-cron-comp {
  .detail-line {
    display: flex;
    align-items: center;
    margin: 20px 0;
    .detail-label {
      display: inline-block;
      width: 100px;
      color: #000;
      font-size: 16px;
      height: 36px;
      line-height: 36px;
    }
    .ope-button {
      margin-left: 12px;
    }
  }
  .list-line {
    align-items: flex-start;
    .date-button {
      margin-left: 12px;
      background: #f6f6f6;
      cursor: pointer;
      color: #2a4158;
      display: inline-block;
      width: 36px;
      height: 36px;
      text-align: center;
      padding: 0;
    }
    .content-detail-lines {
      margin-top: 20px;
    }
    .execution-line + .execution-line {
      margin-top: 20px;
    }
    .execution-line {
      height: 36px;
      line-height: 36px;
      .error-line {
        color: #ff0000;
      }
    }
    .index-span {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      width: 45px;
      background: #ecf5ff;
      color: #409eff;
      border-radius: 4px;
      text-align: center;
      margin-right: 9px;
    }
    .execution-detail {
      color: #909399;
      font-size: 16px;
    }
    .cron-line {
      display: flex;
      .cron-card {
        display: flex;
        flex-direction: column;
        .cron-label {
          display: inline-block;
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 500;
          color: #909399;
          padding-left: 15px;
        }
      }
      .cron-card + .cron-card {
        margin-left: 12px;
      }
    }
  }
  .w400 {
    width: 400px;
  }
  .w56 {
    width: 56px;
  }
  .w80 {
    width: 80px;
  }
  .split-span {
    margin: 0 12px;
  }
}
</style>