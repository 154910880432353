var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-action-comp" },
    [
      _c(
        "el-form",
        { ref: "actionForm", attrs: { model: _vm.form } },
        [
          _c("AceEditor", {
            key: "customizeJson",
            ref: "aceEditorRef",
            staticStyle: { border: "1px solid #ebecec" },
            attrs: {
              lang: "text",
              theme: "chrome",
              height: "200",
              width: "719",
            },
            on: { init: _vm.editorInit },
            model: {
              value: _vm.form.message,
              callback: function ($$v) {
                _vm.$set(_vm.form, "message", $$v)
              },
              expression: "form.message",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }