<template>
  <div
    :class="['rule-detail-component', source !== 'dataset' && 'app-container']"
  >
    <div class="card-container">
      <div class="title-line">
        <span class="title-label"> 基础信息 </span>
        <span class="title-tips">
          <i class="el-icon-info" />
          用户配置的规则描述
        </span>
      </div>
      <div class="form-container">
        <ruleBasicForm
          ref="basicRef"
          :source="source"
          :currentState="currentState"
          :currentRowData="currentRowData"
          :ruleLoading="ruleLoading"
          @ruleTypeChange="ruleTypeChange"
          @updateField="fieldsUpdate"
        />
      </div>
    </div>
    <div class="card-container">
      <div class="title-line">
        <span class="title-label"> 规则配置 </span>
        <span class="title-tips">
          <i class="el-icon-info" />
          用户自由配置的规则条件和执行操作
        </span>
      </div>
      <div class="component-container" v-loading="ruleLoading">
        <div class="condition-component">
          <component
            :is="currentConditionType"
            :currentRuleType="currentRuleType"
            :currentRowData="currentRowData"
            :currentState="currentState"
            :source="source"
            ref="conditionRef"
          ></component>
        </div>
        <div class="action-component">
          <actionComponent
            ref="actionsRef"
            :currentRuleType="currentRuleType"
            :currentRowData="currentRowData"
            :currentState="currentState"
            :source="source"
          />
        </div>
      </div>
    </div>
    <div class="button-container" v-if="source !== 'dataset'">
      <el-button
        type="primary"
        v-if="currentState !== 'view'"
        @click="saveHandler"
        v-preventReClick
        >保存</el-button
      >
      <el-button v-if="currentState !== 'view'" @click="cancelHandler"
        >取消</el-button
      >
      <el-button
        type="primary"
        v-if="currentState === 'view'"
        v-loading="ruleLoading"
        @click="changeToEdit"
        >编辑</el-button
      >
      <el-button
        type="danger"
        :disabled="
          (currentRowData && currentRowData.ruleStatus === 'ENABLE') ||
          currentState === 'view'
        "
        v-if="currentState !== 'add'"
        @click="deleteHandler"
        >删除</el-button
      >
    </div>
  </div>
</template>

<script>
import ruleBasicForm from "./ruleBasicForm.vue";
import scheduleCondition from "./ruleConfigDetail/scheduleCondition.vue";
import normalCondition from "./ruleConfigDetail/normalCondition.vue";
import actionComponent from "./ruleConfigDetail/actionComponent.vue";
import { deepClone } from "@/utils/utils.js";
import { debounce } from "lodash";
import {
  createRule,
  deleteRuleById,
  updateRule,
  getRuleDetailByKey,
} from "@/api/ruge/vlink/rule/rule";
export default {
  name: "ruleDetailComponent",
  props: {
    // 初始化来源是url/dataset，默认url
    source: {
      type: String,
      default() {
        return "url";
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    ruleBasicForm,
    scheduleCondition,
    normalCondition,
    actionComponent,
  },
  data() {
    return {
      currentState: "add",
      currentRowData: null,
      /**
       * linkage: 联动规则
       * alarm: 告警规则
       * schedule: 时间表
       * deviceCall: 设备通信
       * serverSub: 服务端订阅
       */
      currentRuleType: "linkage",
      /**
       * 时间表：scheduleCondition
       * 其他：normalCondition
       */
      currentConditionType: "normalCondition",
      ruleLoading: false,
      successMessageDebounce: debounce((result) => {
        this.$message.success(result || "更新规则成功！");
      }, 500),
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    async saveHandler() {
      // 校验基础信息
      const basicDatas = await this.$refs.basicRef.checkValidAndEmitDatas();
      console.log("basicDatas", basicDatas);
      // 校验规则配置必填，并组装数据
      const ruleList = await this.$refs.conditionRef.checkValidAndEmitDatas();
      if (!ruleList) return;
      // 校验动作必填，并组装数据
      let actionList = await this.$refs.actionsRef.checkValidAndEmitDatas();
      actionList = this.buildActionDatas(actionList);
      const ruleConfig = {
        conditionGroup: ruleList,
        actions: actionList,
      };
      basicDatas.ruleConfig = JSON.stringify(ruleConfig);
      console.log("创建规则：", basicDatas);
      console.log("aaa-ruleConfig", ruleConfig);
      if (this.checkSpecialRule(ruleConfig)) {
        this.$message.warning("跨设备属性的条件无法恢复，请修改后重新保存！");
        return;
      }
      this.requestHandler(basicDatas);
    },
    checkSpecialRule(ruleConfig) {
      // 动作中类型是告警
      // 并且告警状态为恢复
      // 并且选择的是所有条件，则条件中只能选同一个产品和设备和属性，并且属性不能选多个，否则保存时提示
      const { actions, conditionGroup } = ruleConfig;
      // 如果选择的是条件之一，return false
      if (conditionGroup.logic === "OR") {
        return false;
      }
      let isAlarmAndRestore = false;
      for (let action in actions) {
        let currentAction = actions[action];
        if (
          currentAction.type === "alarm" &&
          currentAction.alarm.status === "normal"
        ) {
          isAlarmAndRestore = true;
          break;
        }
      }
      // 如果动作中没有告警并且状态为恢复，return false
      if (!isAlarmAndRestore) {
        return false;
      }
      // 1. 不能出现两种产品
      // 2. 不能出现两种设备
      // 3. 不能出现两种属性
      // 4. 不能出现两种模块
      let productKeyList = [];
      let deviceNameList = [];
      let propertyNameList = [];
      let moduleNameList = [];
      conditionGroup.conditions.forEach((item) => {
        const { productKey, deviceName, filters } = item.condition || {};
        if (!productKeyList.includes(productKey)) {
          productKeyList.push(productKey);
        }
        if (deviceName && !deviceNameList.includes(deviceName)) {
          deviceNameList.push(deviceName);
        }
        filters.forEach((filter) => {
          if (!propertyNameList.includes(filter.propertyName)) {
            propertyNameList.push(filter.propertyName);
          }
          if (!moduleNameList.includes(filter.moduleName)) {
            moduleNameList.push(filter.moduleName);
          }
        });
      });
      if (
        productKeyList.length > 1 ||
        deviceNameList.length > 1 ||
        propertyNameList.length > 1 ||
        moduleNameList.length > 1
      ) {
        return true;
      }
      return false;
    },
    requestHandler(params) {
      if (["add", "copy"].includes(this.currentState)) {
        params.ruleStatus = "ENABLE";
        createRule(params).then((res) => {
          this.successHandler(params, res, "add");
        });
      } else {
        updateRule(params).then((res) => {
          this.successHandler(params, res, "edit");
        });
      }
    },
    fieldsUpdate(params) {
      updateRule(params).then(() => {
        this.successMessageDebounce();
        const { ruleKey } = this.$route.query;
        this.getRuleDetail(ruleKey);
      });
    },
    successHandler(params, res, type) {
      console.log("params", params.ruleStatus);
      this.successMessageDebounce(
        type === "add" ? "保存规则成功！" : "更新规则成功！"
      );
      // 当前状态变成编辑状态，并刷新$route.query
      this.currentState = "view";

      // 新增状态默认Enable，编辑取当前状态
      if (type === "add") {
        res.ruleStatus = "ENABLE";
        let newQuery = deepClone(this.$route.query);
        newQuery.ruleKey = res.ruleKey;
        newQuery.type = "view";
        this.$router.replace({
          query: newQuery,
        });
      }
      this.currentRowData = type === "add" ? res : params;
      this.$nextTick(() => {
        this.$eventBus.$emit(
          "setDetailAlias",
          type === "add" ? res.ruleName : params.ruleName
        );
      });
    },
    changeToEdit() {
      this.currentState = "edit";
    },
    cancelHandler() {
      const { currentState } = this;
      const warningMessage =
        currentState === "add"
          ? "取消将无法保存当前添加过的数据，确定取消吗？"
          : "取消将无法保存当前修改过的数据，确定取消吗？";
      this.$confirm(warningMessage, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      })
        .then(() => {
          debugger;
          if (currentState === "add") {
            // 新增状态，取消跳回列表页
            this.$router.push("/iot/rule/list");
          } else if (currentState === "edit") {
            // 编辑状态，取消直接还原
            let newQuery = deepClone(this.$route.query);
            newQuery.type = "view";
            this.$router.replace({
              query: newQuery,
            });
            // 重新初始化
            this.initDatas("view");
          }
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    deleteHandler() {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          const { ruleId, ruleType } = this.currentRowData;
          deleteRuleById({
            ruleId,
            ruleType,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.$router.push("/iot/rule/list");
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    buildActionDatas(actionList) {
      let actions = [];
      actionList.forEach((item) => {
        let tempObj = {
          type: item.currentActionType,
          extendConfig: JSON.stringify(item),
        };
        if (item.currentActionType === "service") {
          tempObj["service"] = {
            deviceName: item.productOrDevice[1],
            productKey: item.productOrDevice[0],
            moduleName: item.moduleOrService[0],
            serviceName: item.moduleOrService[1],
            /**
             * 先过滤有值的
             * 再便利拼接key和value，通过reduce和扩展运算符处理成Object，并转字符串
             */
            inputData:
              item.paramList &&
              JSON.stringify(
                item.paramList
                  .filter((v) => v.value)
                  .reduce((result, currentValue) => {
                    return {
                      ...result,
                      ...{ [currentValue.paramName]: currentValue.value },
                    };
                  }, {})
              ),
          };
        }
        if (item.currentActionType === "property") {
          tempObj["property"] = {
            deviceName: item.productOrDevice[1],
            productKey: item.productOrDevice[0],
            moduleName: item.moduleOrProperty[0],
            propertyName: item.moduleOrProperty[1],
            value: item.value,
            delayTime: item.delayTime,
          };
        }
        if (item.currentActionType === "message") {
          tempObj["property"] = {
            message: {
              payload: item.message,
            },
          };
        }
        if (item.currentActionType === "alarm") {
          tempObj["alarm"] = {
            count: item.times,
            description:
              item.alarmStatus === "alarm"
                ? item.alarmDetail
                : item.restoreDetail,
            level: item.alarmLevel,
            relationRules:
              item.alarmStatus === "alarm" ? [] : item.relatedAlarm,
            status: item.alarmStatus,
            timeLength: item.time,
            type: item.alarmType,
            repeatedAlarm: item.repeatedAlarm,
            maxRepeatedCount: item.maxRepeatedCount,
            isSendSMS: item.isSendSMS,
            phones: item.isSendSMS === "Y" ? item.phones : [],
            isSendEmail: item.isSendEmail,
            emails: item.isSendEmail === "Y" ? item.emails : [],
          };
        }
        // 服务端订阅 && 流转station
        if (item.currentActionType === "dataFlow") {
          tempObj["serverSub"] = {
            consumerGroupKey:
              item.dataFlowType === "serverSub"
                ? item.consumerGroupKey
                : "default",
            consumerGroupName:
              item.dataFlowType === "serverSub" ? item.consumerGroupName : null,
          };
          tempObj.type = item.dataFlowType;
        }
        if (item.currentActionType === "deviceCall") {
          tempObj["deviceCall"] = {
            productKey: item.productOrDevice[0],
            deviceName: item.productOrDevice[1],
            topicSelect: item.topicSelect,
          };
        }
        actions.push(tempObj);
      });
      return actions;
    },
    ruleTypeChange(ruleType) {
      // 条件类型
      this.currentConditionType =
        ruleType === "schedule" ? "scheduleCondition" : "normalCondition";
      this.currentRuleType = ruleType;
    },
    initDatas(stateSet) {
      if (this.source === "dataset") {
        const response = deepClone(this.dataset);
        this.currentRowData = response;
        this.currentRuleType = response.ruleType;
        this.currentConditionType =
          response.ruleType === "schedule"
            ? "scheduleCondition"
            : "normalCondition";
        this.currentState = "view";
        return;
      }
      const { ruleKey, type } = this.$route.query;
      // 如果ruleKey不存在，则为新增
      if (!ruleKey) {
        // 新增需要把action中只能选一个流转到station的限制去掉
        this.$store.commit("SET_STATION_SELECTED", false);
        this.currentState = "add";
        this.$nextTick(() => {
          this.$eventBus.$emit("setDetailTitle", "添加规则");
        });
      }
      // 如果ruleKey存在，则为type或者是预览状态
      if (ruleKey) {
        this.currentState = stateSet || type || "view";
        // copy
        if (ruleKey && type === "copy") {
          this.currentState = "add";
        }
        this.getRuleDetail(ruleKey);
      }
      console.log("this.currentState", this.currentState);
    },
    getRuleDetail(ruleKey) {
      this.ruleLoading = true;
      getRuleDetailByKey({ ruleKey })
        .then((res) => {
          const { type } = this.$route.query;
          const response = deepClone(res);
          // 如果是copy，ruleName后加"-copy"
          if (type === "copy") {
            response.ruleName += "-copy";
          }
          this.currentRowData = response;
          this.currentRuleType = response.ruleType;
          this.currentConditionType =
            response.ruleType === "schedule"
              ? "scheduleCondition"
              : "normalCondition";
          this.$eventBus.$emit("setDetailAlias", this.currentRowData.ruleName);
        })
        .finally(() => {
          this.ruleLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.rule-detail-component {
  background: #ffffff;
  padding: 40px 32px;
  .card-container {
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    width: 100%;
    .form-container {
      margin-top: 20px;
    }
    .component-container {
      margin-top: 20px;
    }
    .title-line {
      display: flex;
      align-items: center;
      .title-label {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      .title-tips {
        margin-left: 16px;
        font-size: 12px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #000;
        opacity: 0.7;
        i {
          opacity: inherit;
        }
      }
    }
  }
  .card-container + .card-container {
    margin-top: 24px;
  }
  .button-container {
    margin-top: 24px;
  }
}
</style>