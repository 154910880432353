<template>
  <div class="data-flow-comp">
    <el-form :model="form" ref="actionForm">
      <span class="select-label"> 流转方式： </span>
      <el-select
        :disabled="currentState === 'view'"
        placeholder="流转方式"
        v-model="form.dataFlowType"
        @change="dataFlowChange"
      >
        <el-option
          value="stationSub"
          v-show="!stationSelected"
          label="数据流转到Rdata"
        ></el-option>
        <el-option value="serverSub" label="服务端订阅"></el-option>
      </el-select>
      <span class="consumer-container" v-if="form.dataFlowType === 'serverSub'">
        <span class="select-label"> 消费组名称： </span>
        <el-form-item
          prop="consumerGroupName"
          :rules="
            form.dataFlowType === 'serverSub' && [
              {
                required: true,
                message: '请选择消费组',
                trigger: ['blur', 'change'],
              },
            ]
          "
        >
          <el-select
            :disabled="currentState === 'view'"
            placeholder="消费组名称"
            v-model="form.consumerGroupName"
            @change="consumerGroupChange"
          >
            <el-option
              v-for="(item, index) in consumerGroupList"
              :key="index"
              :value="item.consumerGroupName"
              :label="item.consumerGroupName"
            ></el-option>
          </el-select>
        </el-form-item>
      </span>
    </el-form>
  </div>
</template>

<script>
import { actionComponentMixins } from "@/mixins/actionComponentMixins.js";
export default {
  mixins: [actionComponentMixins],
  props: {
    consumerGroupList: {
      type: Array,
      required: true,
    },
    currentState: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        dataFlowType: "stationSub",
        consumerGroupName: "",
        consumerGroupKey: "",
      },
      // 所有动作中是否已选流转到station，默认false，监听store中的stationSelected
      stationSelected: false,
    };
  },
  watch: {
    // 多个动作中只能选择一个流转到Station
    "$store.getters.stationSelected": {
      handler(val) {
        console.log("val----------", val);
        this.stationSelected = val;
      },
      immediate: true,
    },
  },
  methods: {
    consumerGroupChange(value) {
      this.consumerGroupList.forEach((item) => {
        if (item.consumerGroupName === value) {
          this.form.consumerGroupKey = item.consumerGroupKey;
        }
      });
    },
    dataFlowChange(value) {
      this.$store.commit("SET_STATION_SELECTED", value === "stationSub");
      // 不管选择的是哪个，当前在操作组件的数据流转到Station不需要隐藏
      this.$nextTick(() => {
        this.stationSelected = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.data-flow-comp {
  .select-label {
    // background: #f8f9f9;
    border-radius: 4px;
    color: #606266;
    text-align: center;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    margin-right: 12px;
    width: 85px;
  }
  .consumer-container {
    margin-left: 12px;
  }
}
</style>