<template>
  <div class="schedule-time-comp">
    <el-form :model="form" ref="scheduleForm">
      <div class="detail-line list-line">
        <span class="detail-label">日期</span>
        <div>
          <el-radio-group
            v-model="form.limitOrNot"
            @change="limitChange"
            :disabled="currentState === 'view'"
          >
            <el-radio-button label="noLimit">不限日期</el-radio-button>
            <el-radio-button label="limit">具体日期</el-radio-button>
          </el-radio-group>
          <div v-if="form.limitOrNot === 'limit'">
            <div
              v-for="(item, index) in form.dataRangeList"
              :key="index"
              class="content-detail-lines"
            >
              <el-date-picker
                :disabled="currentState === 'view'"
                v-model="item.dateRange"
                type="datetimerange"
                class="w400"
                :range-separator="$t('commons.rangeSeparator')"
                :start-placeholder="$t('commons.startDate')"
                :end-placeholder="$t('commons.endDate')"
              >
              </el-date-picker>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除生效日期"
                placement="bottom"
              >
                <el-button
                  :disabled="currentState === 'view'"
                  v-show="form.dataRangeList.length > 1"
                  @click="deleteDate(index)"
                  class="date-button"
                  icon="el-icon-delete"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="添加生效日期"
                placement="bottom"
              >
                <el-button
                  :disabled="currentState === 'view'"
                  @click="addDate"
                  class="date-button"
                  icon="el-icon-plus"
                ></el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="detail-line list-line">
        <span class="detail-label">重复</span>
        <div>
          <el-radio-group
            v-model="form.repeatPeriod"
            :disabled="currentState === 'view'"
          >
            <el-radio-button label="day"> 每几天 </el-radio-button>
            <el-radio-button label="week">每周几</el-radio-button>
          </el-radio-group>
          <div class="content-detail-lines" v-if="form.repeatPeriod === 'week'">
            <el-checkbox-group
              :disabled="currentState === 'view'"
              v-model="form.weeks"
              :min="1"
            >
              <el-checkbox
                v-for="week in weeksList"
                :label="week.value"
                :key="week.value"
                >{{ week.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="content-detail-lines" v-else>
            <span> 每 </span>
            <el-form-item
              prop="days"
              :rules="
                form.repeatPeriod === 'day' && [
                  {
                    required: true,
                    message: '请填写天数',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-input-number
                :disabled="currentState === 'view'"
                class="split-span w56"
                v-model="form.days"
                :controls="false"
                :min="1"
              ></el-input-number>
            </el-form-item>
            <span> 天 </span>
          </div>
        </div>
      </div>
      <div class="detail-line list-line">
        <span class="detail-label">时间</span>
        <div>
          <el-radio-group
            :disabled="currentState === 'view'"
            v-model="form.executionFrequency"
          >
            <el-radio-button label="once">执行一次</el-radio-button>
            <el-radio-button label="repeat">重复执行</el-radio-button>
          </el-radio-group>
          <div
            class="content-detail-lines"
            v-if="form.executionFrequency === 'once'"
          >
            <el-form-item
              prop="executionTimes"
              :rules="
                form.executionFrequency === 'once' && [
                  {
                    required: true,
                    message: '请选择执行时间',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-time-picker
                :disabled="currentState === 'view'"
                v-model="form.executionTimes"
                value-format="HH:mm:ss"
                placeholder="执行时间"
              >
              </el-time-picker>
            </el-form-item>
          </div>
          <div
            class="content-detail-lines"
            v-if="form.executionFrequency === 'repeat'"
          >
            <!-- 08 至 12 点，第 X 分开始，每 Y 小时执行一次 -->
            <el-select
              :disabled="currentState === 'view'"
              class="w98"
              v-model="form.executionTimeRange[0]"
              placeholder="请选择"
              @change="(value) => executionTimeChange(value, 'start')"
            >
              <el-option
                v-for="item in executionTimeOption.start"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span class="split-span">至</span>
            <el-select
              :disabled="currentState === 'view'"
              class="w98"
              v-model="form.executionTimeRange[1]"
              placeholder="请选择"
              @change="(value) => executionTimeChange(value, 'end')"
            >
              <el-option
                v-for="item in executionTimeOption.end"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span class="ml12">点，第</span>
            <el-form-item
              prop="beginMinute"
              :rules="
                form.executionFrequency === 'repeat' && [
                  {
                    required: true,
                    message: '开始分钟必填',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-input-number
                :disabled="currentState === 'view'"
                class="split-span w56"
                v-model="form.beginMinute"
                :controls="false"
                :min="0"
                :max="59"
              ></el-input-number>
            </el-form-item>
            <span class="split-span">分开始，每</span>
            <el-form-item
              prop="executionCount"
              :rules="
                form.executionFrequency === 'repeat' && [
                  {
                    required: true,
                    message: '执行频率必填',
                    trigger: ['blur', 'change'],
                  },
                ]
              "
            >
              <el-input-number
                :disabled="currentState === 'view'"
                class="split-span w56"
                v-model="form.executionCount"
                :controls="false"
                :min="1"
                :max="23"
              ></el-input-number>
            </el-form-item>
            <span> 小时执行一次 </span>
          </div>
        </div>
      </div>
      <div class="detail-line list-line">
        <span class="detail-label">执行时间</span>
        <div v-if="showResult">
          <div
            class="execution-line"
            v-for="(item, index) in resultList"
            :key="index"
          >
            <span class="index-span">{{ index + 1 }}</span>
            <span class="execution-detail">{{ item }}</span>
          </div>
        </div>
        <div class="execution-line" v-else>
          <span class="error-line">Cron表达式有误，无法计算执行时间！</span>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { cronResultMixins } from "@/mixins/cronResultMixins.js";
import { cronValidate } from "@/utils/utils.js";
import { cloneDeep } from "lodash";
const defaultTimeList = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];
const DefaultForm = {
  // 是否限制日期：不限日期：noLimit；具体日期：Limit
  limitOrNot: "noLimit",
  // 具体日期list
  dataRangeList: [
    {
      dateRange: null,
    },
  ],
  repeatPeriod: "day",
  timeRangeList: [{ timeRange: null }],
  days: 1,
  weeks: [1, 2, 3, 4, 5, 6, 7],
  executionFrequency: "once",
  executionTimes: "08:30:00",
  executionTimeRange: [8, 12],
  beginMinute: 30,
  executionCount: 1,
  cron: "0 30 8 * * ? *",
};
export default {
  name: "scheduleTime",
  mixins: [cronResultMixins],
  props: {
    currentState: {
      type: String,
      required: true,
    },
    extendConfig: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      require: false,
    },
  },
  watch: {
    extendConfig: {
      handler(val) {
        if (val && this.currentState !== "add") {
          this.form =
            this.type === "timeSchedule" ? val : cloneDeep(DefaultForm);
        }
      },
      immediate: true,
    },
    form: {
      handler(form) {
        this.cronChange();
      },
      // immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      defaultTimeList,
      showResult: false,
      executionTimeOption: {
        start: defaultTimeList.filter((item) => item < 23),
        end: defaultTimeList.filter((item) => item < 23),
      },
      weeksList: [
        { value: 1, label: "周日" },
        { value: 2, label: "周一" },
        { value: 3, label: "周二" },
        { value: 4, label: "周三" },
        { value: 5, label: "周四" },
        { value: 6, label: "周五" },
        { value: 7, label: "周六" },
      ],
      form: cloneDeep(DefaultForm),
      resultList: [],
    };
  },
  mounted() {
    this.cronChange();
  },
  methods: {
    limitChange() {
      this.form.dataRangeList = [
        {
          dateRange: null,
        },
      ];
    },
    checkValidAndEmitDatas(scheduleType) {
      return new Promise((resolve) => {
        this.$refs.scheduleForm.validate((valid) => {
          if (valid) {
            let resultObj = {
              triggerCron: this.form.cron,
              conditions:
                this.form.limitOrNot === "noLimit"
                  ? [{ enableDate: null }]
                  : this.form.dataRangeList.map((item) => {
                      return {
                        enableDate: item.dateRange,
                      };
                    }),
              scheduleType,
              extendConfig: JSON.stringify(this.form),
            };
            resolve(resultObj);
          }
        });
      });
    },
    executionTimeChange(value, type) {
      if (type === "start") {
        if (value >= this.form.executionTimeRange[1]) {
          this.form.executionTimeRange[1] = value + 1;
        }
        this.executionTimeOption.end = defaultTimeList.filter(
          (item) => item > value
        );
      }
    },
    cronChange() {
      this.$refs.scheduleForm.validate((valid) => {
        if (!valid) {
          this.showResult = false;
          return;
        }
        // 通过用户选择，构建出cron表达式
        const curCron = this.buildCronExpression();
        // 校验表达式是否合法
        if (!cronValidate(curCron)) {
          // this.$message.warning("cron表达式格式不正确");
          this.showResult = false;
          return;
        }
        // 获取当前cron表达式的最近5次执行时间
        this.form.cron = curCron;
        console.log("xxx-start", new Date().getTime());
        this.expressionChange(curCron);
        console.log("xxx-end", new Date().getTime());
        this.showResult = true;
      });
    },
    buildCronExpression() {
      const {
        repeatPeriod,
        executionTimes,
        executionFrequency,
        executionTimeRange,
        executionCount,
        beginMinute,
        days,
        weeks,
      } = this.form;
      let second = 0;
      let minute = 30;
      let hour = 8;
      let day = 1;
      let month = "*";
      let week = "*";
      let year = "*";
      // 执行一次 || 重复执行
      if (executionFrequency === "once") {
        second = executionTimes.split(":")[2];
        minute = executionTimes.split(":")[1];
        hour = executionTimes.split(":")[0];
      } else {
        minute = beginMinute;
        let hourList = [];
        for (
          let i = executionTimeRange[0];
          i <= executionTimeRange[1];
          i = i + executionCount
        ) {
          hourList.push(i);
        }
        hour = hourList.join(",");
      }
      // 每几天 || 每周几
      if (repeatPeriod === "day") {
        day = days === "*" ? 1 : `1/${days}`;
        week = "?";
      } else {
        day = "?";
        week = weeks.join(",");
      }
      second = String(second).padStart(2, "0");
      return `${second} ${minute} ${hour} ${day} ${month} ${week} ${year}`;
    },
    addDate() {
      this.form.dataRangeList.push({
        dateRange: null,
      });
    },
    deleteDate(index) {
      this.form.dataRangeList.splice(index, 1);
    },
    addTime() {
      this.form.timeRangeList.push({
        timeRang: null,
      });
    },
    deleteTime(index) {
      this.form.timeRangeList.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.schedule-time-comp {
  .detail-line {
    display: flex;
    align-items: center;
    margin: 20px 0;
    .detail-label {
      display: inline-block;
      width: 100px;
      color: #000;
      font-size: 16px;
      height: 36px;
      line-height: 36px;
    }
  }
  .list-line {
    align-items: flex-start;
    .date-button {
      margin-left: 12px;
      background: #f6f6f6;
      cursor: pointer;
      color: #2a4158;
      display: inline-block;
      width: 36px;
      height: 36px;
      text-align: center;
      padding: 0;
    }
    .content-detail-lines {
      margin-top: 20px;
    }
    .execution-line + .execution-line {
      margin-top: 20px;
    }
    .execution-line {
      height: 36px;
      line-height: 36px;
      .error-line {
        color: #ff0000;
      }
    }
    .index-span {
      display: inline-block;
      height: 24px;
      line-height: 24px;
      width: 45px;
      background: #ecf5ff;
      color: #409eff;
      border-radius: 4px;
      text-align: center;
      margin-right: 9px;
    }
    .execution-detail {
      color: #909399;
      font-size: 16px;
    }
  }
  .ml12 {
    margin-left: 12px;
  }
  .w400 {
    width: 400px;
  }
  .w56 {
    width: 56px;
  }
  .w98 {
    width: 98px;
  }
  .split-span {
    margin: 0 12px;
  }
  ::v-deep .el-radio-button__inner {
    min-width: 98px;
  }
  ::v-deep .el-form-item {
    display: inline-block;
  }
}
</style>