<template>
  <div class="rule-time-config">
    <el-dialog
      :title="disabled ? '查看生效时间' : '请配置生效时间'"
      :visible.sync="show"
      append-to-body
      width="870px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="time-config-dialog">
        <div class="filter-line">
          <span class="">时间间隔：</span>
          <el-select
            v-model="timeStep"
            :disabled="disabled"
            placeholder="请选择"
            @change="stepChange"
          >
            <el-option
              v-for="item in stepOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="config-detail">
          <div class="date-line" @mouseleave="weekMouseLeaveHandler">
            <span
              class="single-date"
              v-for="(item, index) in dateList"
              :key="index"
              @mouseenter="weekMouseEnterHandler(index)"
              @mousedown="weekMouseDownHandler(index)"
              @mouseup="weekMouseUpHandler(index)"
            >
              {{ item.label }}
            </span>
          </div>
          <div class="time-detail" @mouseleave="mouseLeaveHandler">
            <div class="time-line">
              <span
                class="single-time"
                v-for="(item, index) in timeList"
                :key="index"
              >
                {{ item }}
              </span>
            </div>
            <div
              class="line-container"
              v-for="(item, index) in detailList"
              :key="index"
            >
              <div
                class="single-time-detail"
                :style="{
                  background: sub.active ? 'rgba(178,242,187,0.4)' : '#ffffff',
                  border: sub.active
                    ? '1px solid #40c057'
                    : '1px solid #e5e5e5',
                }"
                v-for="(sub, subIndex) in item"
                :key="subIndex"
                @mousedown="mouseDownHandler(`${index}-${subIndex}`)"
                @mouseenter="mouseEnterHandler(`${index}-${subIndex}`)"
                @mouseup="mouseUpHandler(sub)"
              >
                <!-- {{ `${index}-${subIndex}` }} -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="!disabled" @click="$emit('close')">取 消</el-button>
        <el-button v-if="!disabled" type="primary" @click="submitHandler"
          >确 定</el-button
        >
        <el-button v-if="disabled" type="primary" @click="$emit('close')"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "rule-time-config",
  props: {
    dataset: {
      type: String,
      default() {
        return "";
      },
    },
    show: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      timeStep: 30,
      timeStepBak: 30,
      stepOptions: [
        {
          label: "1分钟",
          value: 1,
        },
        {
          label: "5分钟",
          value: 5,
        },
        {
          label: "10分钟",
          value: 10,
        },
        {
          label: "15分钟",
          value: 15,
        },
        {
          label: "20分钟",
          value: 20,
        },
        {
          label: "30分钟",
          value: 30,
        },
        {
          label: "60分钟",
          value: 60,
        },
      ],
      dateList: [
        {
          label: "周一",
          value: "Mon",
        },
        {
          label: "周二",
          value: "Tues",
        },
        {
          label: "周三",
          value: "Wed",
        },
        {
          label: "周四",
          value: "Thur",
        },
        {
          label: "周五",
          value: "Fri",
        },
        {
          label: "周六",
          value: "Sat",
        },
        {
          label: "周日",
          value: "Sun",
        },
      ],
      weekMap: {
        0: "Mon",
        1: "Tues",
        2: "Wed",
        3: "Thur",
        4: "Fri",
        5: "Sat",
        6: "Sun",
      },
      timeList: [],
      detailList: [],
      detailListBak: [],
      startIndex: "",
      endIndex: "",
      moveFlag: false,
      weekMoveFlag: false,
      weekStartIndex: "",
      weekEndIndex: "",
    };
  },
  watch: {
    dataset: {
      handler(datas) {
        if (datas) {
          const { timeStep, detailList } = JSON.parse(datas);
          this.timeStep = timeStep;
          this.detailList = cloneDeep(detailList);
          this.freshStep(detailList);
        } else {
          this.freshStep();
        }
      },
      immediate: true,
    },
  },
  methods: {
    submitHandler() {
      const resultList = {};
      if (this.checkSelectedCount() === 0) {
        this.$message.warning("请选择生效时间");
        return;
      }
      // 过滤active的数据，并且根据timeList来拼接时间段
      this.detailList.forEach((item, index) => {
        resultList[this.weekMap[index]] = item
          .filter((item) => item.active)
          .map((single, singleIndex) => {
            const startTime = this.timeList[single.value];
            const endTime = this.timeList[single.value + 1] || "24:00";
            const timeStr = `${startTime}-${endTime}`;
            return timeStr;
          });
      });
      // 合并连续的时间段
      for (let key in resultList) {
        if (resultList[key].length) {
          // 需要splice所以倒序
          for (let i = resultList[key].length - 1; i >= 0; i--) {
            if (!resultList[key][i - 1]) break;
            const [start, end] = resultList[key][i].split("-");
            const [beforeStart, beforeEnd] = resultList[key][i - 1].split("-");
            // 当前的开始等于上一个的结束判断两个时间是连续的，则修改上一条数据的end并删除当前条
            if (start === beforeEnd) {
              resultList[key][i - 1] = `${beforeStart}-${end}`;
              resultList[key].splice(i, 1);
            }
          }
        }
      }
      let params = {
        config: resultList,
        extend: JSON.stringify({
          detailList: this.detailList,
          timeStep: this.timeStep,
        }),
      };
      console.log("submit-params", params);
      this.$emit("submit", params);
    },
    checkSelectedCount() {
      let count = 0;
      this.detailList.forEach((item) => {
        count += item.filter((item) => item.active).length;
      });
      return count;
    },
    unFocus() {
      if (document.selection) {
        document.selection.empty();
      } else {
        window.getSelection().removeAllRanges();
      }
    },
    mouseEnterHandler(index) {
      if (!this.moveFlag) return;
      this.detailList = cloneDeep(this.detailListBak);
      this.endIndex = index;
      this.freshAllDatas();
    },
    mouseDownHandler(index) {
      if (this.disabled) return;
      // 如果之前有框选会产生selection，再次框选的时候会有问题，要清理掉
      this.unFocus();
      this.detailListBak = cloneDeep(this.detailList);
      this.startIndex = index;
      this.moveFlag = true;
    },
    mouseUpHandler(item) {
      if (this.disabled) return;
      if (!this.endIndex) {
        item.active = !item.active;
      }
      this.moveFlag = false;
      this.startIndex = "";
      this.endIndex = "";
    },
    mouseLeaveHandler() {
      this.moveFlag = false;
      this.startIndex = "";
      this.endIndex = "";
    },
    weekMouseLeaveHandler() {
      this.weekMoveFlag = false;
      this.weekStartIndex = "";
      this.weekEndIndex = "";
    },
    weekMouseEnterHandler(index) {
      if (!this.weekMoveFlag) return;
      this.detailList = cloneDeep(this.detailListBak);
      this.weekEndIndex = index;
      this.freshWeekAllDatas();
    },
    weekMouseDownHandler(index) {
      console.log("xxx1", index);
      console.log("xxx2", this.detailList);
      if (this.disabled) return;
      this.unFocus();
      this.detailListBak = cloneDeep(this.detailList);
      this.weekStartIndex = index;
      this.weekMoveFlag = true;
    },
    weekMouseUpHandler() {
      if (this.disabled) return;
      if (!this.weekEndIndex) {
        // 点击整列取反
        this.detailReverseByIndex(this.weekStartIndex);
      }
      this.weekMoveFlag = false;
      this.weekStartIndex = "";
      this.weekEndIndex = "";
    },
    detailReverseByIndex(reverseIndex) {
      this.detailList.forEach((item, index) => {
        if (index === reverseIndex) {
          item.forEach((subItem) => {
            subItem.active = !subItem.active;
          });
        }
      });
    },
    freshWeekAllDatas() {
      const max = Math.max(this.weekStartIndex, this.weekEndIndex);
      const min = Math.min(this.weekStartIndex, this.weekEndIndex);
      this.detailList.forEach((item, index) => {
        if (index >= min && index <= max) {
          item.forEach((subItem) => {
            subItem.active = !subItem.active;
          });
        }
      });
    },
    freshAllDatas() {
      // 计算开始、结束点的坐标包含哪些item
      const [start1, end1] = this.startIndex.split("-");
      const [start2, end2] = this.endIndex.split("-");
      const startList = this.dealIndexList(start1, start2);
      const endList = this.dealIndexList(end1, end2);
      const containerList = [];
      startList.forEach((item) => {
        endList.forEach((subItem) => {
          containerList.push(`${item}-${subItem}`);
        });
      });
      // 找到detailList中被框中的item，设置active取反
      this.detailList.forEach((item, index) => {
        item.forEach((subItem, subIndex) => {
          const current = `${index}-${subIndex}`;
          if (containerList.includes(current)) {
            subItem.active = !subItem.active;
          }
        });
      });
    },
    dealIndexList(index1, index2) {
      let result = [];
      let start = Math.min(index1, index2);
      let max = Math.max(index1, index2);
      while (start <= max) {
        result.push(start);
        start++;
      }
      return result;
    },
    stepChange() {
      this.$confirm(
        `切换时间间隔将清空下面已勾选数据！`,
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.freshStep();
        })
        .catch(() => {
          this.timeStep = this.timeStepBak;
        });
    },
    freshStep(noFlag) {
      this.timeList = this.generateTimes(this.timeStep);
      this.timeStepBak = this.timeStep;
      !noFlag && this.freshDetailList();
    },
    freshDetailList() {
      let result = [];
      this.dateList.forEach((item) => {
        let temp = [];
        this.timeList.forEach((subItem, subIndex) => {
          temp.push({
            active: false,
            value: subIndex,
          });
        });
        result.push(temp);
      });
      this.detailList = cloneDeep(result);
    },
    generateTimes(step) {
      let size = Math.floor((24 * 60) / step) - 1;
      let timeArrays = new Array(size + 1).fill("").map((item, index) => {
        let startVal = index * step;
        let endVal = (index + 1) * step;
        let startHour = Math.floor(startVal / 60);
        let startMinute = startVal % 60;
        let endHour = Math.floor(endVal / 60);
        let endMinute = endVal % 60;
        let startTime =
          (startHour < 10 ? "0" + startHour : startHour) +
          ":" +
          (startMinute === 0
            ? "00"
            : startMinute < 10
            ? "0" + startMinute
            : startMinute);
        let endTime =
          (endHour < 10 ? "0" + endHour : endHour) +
          ":" +
          (endMinute === 0 ? "00" : endMinute);

        return startTime;
      });

      return timeArrays;
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less">
.rule-time-config {
}
.time-config-dialog {
  .filter-line {
    margin-bottom: 15px;
  }
  .config-detail {
    .date-line {
      padding-left: 100px;
      background: #f5f9ff;
      height: 35px;
      line-height: 35px;
      border-radius: 10px 10px 0 0;
      .single-date {
        width: 100px;
        color: #333;
        font-size: 14px;
        display: inline-block;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }
    }
    .time-detail {
      padding-top: 10px;
      max-height: 400px;
      overflow: auto;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-top: none;
      display: flex;
      border-radius: 0 0 10px 10px;
      .time-line {
        width: 100px;
        text-align: center;
        display: flex;
        flex-direction: column;
        .single-time {
          height: 30px;
          line-height: 30px;
          position: relative;
          top: -15px;
        }
      }
      .line-container {
        width: 100px;
        .single-time-detail {
          width: 100px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #e5e5e5;
          cursor: pointer;
        }
      }
    }
  }
}
</style>