<template>
  <div class="device-call-comp">
    <el-form :model="form" ref="actionForm" :rules="rules">
      <span class="split-span" style="margin-left: 0">调用</span>
      <!-- <el-form-item prop="productOrDevice">
        <el-cascader
          :disabled="currentState === 'view'"
          class="w306"
          placeholder="产品/设备"
          popper-class="product-device-poper"
          v-model="form.productOrDevice"
          @change="productOrDeviceChange"
          :props="props"
        ></el-cascader>
      </el-form-item> -->
      <el-form-item prop="product">
        <el-select
          :disabled="currentState === 'view'"
          v-model="form.product"
          placeholder="产品"
          filterable
          class="w175"
          @change="(value) => productChange(value)"
        >
          <el-option
            v-for="item in productOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="device">
        <el-select
          :disabled="currentState === 'view'"
          v-model="form.device"
          placeholder="设备"
          class="w175 ml10"
          filterable
          remote
          clearable
          :remote-method="(value) => deviceRemoteMethod(value)"
          :loading="loading"
        >
          <el-option
            v-for="item in deviceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <span class="split-span">中的</span>
      <el-form-item prop="topicSelect">
        <el-select
          :disabled="currentState === 'view'"
          class="w306"
          placeholder="请选择Topic"
          multiple
          filterable
          clearable
          v-model="form.topicSelect"
        >
          <el-option
            v-for="topic in topicList"
            :label="topic.topicShortName"
            :value="topic.topicClassName"
            :key="topic.topicId"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getProductList,
  findTsl,
  findCustomTopicList,
} from "@/api/ruge/vlink/product/product";
import { findDeviceList, findDevicePage } from "@/api/ruge/vlink/device/device";
import { actionComponentMixins } from "@/mixins/actionComponentMixins.js";
export default {
  mixins: [actionComponentMixins],
  props: {
    currentState: {
      type: String,
      required: true,
    },
  },
  data() {
    const _this = this;
    return {
      form: {
        product: "",
        device: "",
        productOrDevice: [],
        topicSelect: [],
        productObjList: {},
      },
      deviceOptions: [],
      productOptions: [],
      loading: false,
      deviceQueryParams: {
        current: 1,
        rowCount: 50,
        description: null,
        productKey: null,
      },
      topicList: [],
      // props: {
      //   lazy: true,
      //   lazyLoad(node, resolve) {
      //     if (!node) return;
      //     const { level, value } = node;
      //     if (level == 0) {
      //       getProductList().then((productList) => {
      //         const nodes = productList.map((item) => ({
      //           value: item.productKey,
      //           label: item.productName,
      //         }));
      //         resolve(nodes);
      //       });
      //     } else if (level == 1) {
      //       const productKey = value;
      //       findDeviceList({ productKey: productKey }).then((deviceList) => {
      //         const nodes = deviceList.map((item) => {
      //           if (!_this.form.productObjList[productKey]) {
      //             _this.form.productObjList[productKey] = {
      //               parentProductKey: item.parentProductKey,
      //               parentDeviceName: item.parentDeviceName,
      //             };
      //           }
      //           return {
      //             value: item.deviceName,
      //             label: item.deviceName,
      //             leaf: item.deviceName,
      //           };
      //         });
      //         resolve(nodes);
      //       });
      //     } else {
      //       // 选到设备时就不加载了。。否则一直loading
      //       resolve([]);
      //     }
      //   },
      // },
      rules: {
        product: [
          {
            required: true,
            message: "请选择产品",
            trigger: ["blur", "change"],
          },
        ],
        device: [
          {
            required: true,
            message: "请选择设备",
            trigger: ["blur", "change"],
          },
        ],
        topicSelect: [
          {
            required: true,
            message: "请选择Topic",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.getProductListHandler();
  },
  methods: {
    getProductListHandler() {
      getProductList().then((productList) => {
        const nodes = productList.map((item) => ({
          value: item.productKey,
          label: item.productName,
        }));
        nodes.sort((item1, item2) => {
          return item1.label.localeCompare(item2.label, "zh-CN");
        });
        this.productOptions = nodes;
      });
    },
    productChange(value) {
      // 清空一选择的设备、属性
      this.form.device = "";
      this.form.topicSelect = [];
      this.deviceOptions = [];
      // 根据产品获取设备
      this.deviceRemoteMethod("");
      // 根据产品获取服务调用列表
      this.getTopicByProduct(value);
    },
    getTopicByProduct(productKey) {
      findCustomTopicList({ productKey }).then((topicList) => {
        // if (clearFlag) this.form.topicSelect = [];
        this.topicList = topicList;
      });
    },
    deviceRemoteMethod(query, product) {
      if (product || this.form.product) {
        this.loading = true;
        let params = this.deviceQueryParams;
        params.description = query;
        params.productKey = product || this.form.product || "";
        findDevicePage(params)
          .then((res) => {
            const currentDevices = res.rows.map((item) => {
              return {
                value: item.deviceName,
                label: item.description
                  ? `${item.description} (${item.deviceName})`
                  : `${item.deviceName} (${item.deviceName})`,
              };
            });
            this.deviceOptions = currentDevices;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deviceOptions = [];
      }
    },
    // productOrDeviceChange(value) {
    //   const productKey = value[0];
    //   findCustomTopicList({ productKey }).then((topicList) => {
    //     this.form.topicSelect = [];
    //     this.topicList = topicList;
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.device-call-comp {
  .w306 {
    width: 306px;
  }
  .w175 {
    width: 175px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .split-span {
    margin: 0 12px;
  }
}
</style>