<template>
  <div class="rule-basic-form">
    <el-form
      v-loading="ruleLoading"
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row :gutter="48">
        <!-- 规则名称 -->
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.rule.ruleName')"
            prop="ruleName"
            :rules="[
              {
                required: currentState === 'add',
                message: $t('validate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-if="currentState === 'add'"
              :placeholder="$t('commons.pleaseInput')"
              v-model="ruleForm.ruleName"
              maxlength="30"
            ></el-input>
            <span v-else>
              <span v-if="nameEdit">
                <el-input
                  ref="nameInputRef"
                  v-model="ruleForm.ruleName"
                  @blur="fieldsUpdateHandler('ruleName')"
                  @keyup.enter.native="(event) => event.target.blur()"
                ></el-input>
              </span>
              <span v-else>
                {{ ruleForm.ruleName || "-" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="修改规则名称"
                  placement="top"
                >
                  <i
                    class="el-icon-edit"
                    @click="nameEditEnable"
                    v-if="source !== 'dataset'"
                  ></i>
                </el-tooltip>
              </span>
            </span>
          </el-form-item>
        </el-col>
        <!-- 作用域 -->
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.rule.ruleScope')"
            prop="ruleScope"
            :rules="[
              {
                required: currentState === 'add',
                message: $t('validate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-radio-group
              v-if="currentState === 'add'"
              v-model="ruleForm.ruleScope"
            >
              <el-radio :label="'CLOUD'" border>{{
                $t("vlink.rule.cloud")
              }}</el-radio>
              <el-radio :label="'EDGE'" border>{{
                $t("vlink.rule.edge")
              }}</el-radio>
            </el-radio-group>
            <span v-else>
              {{
                ruleForm.ruleScope
                  ? ruleForm.ruleScope === "CLOUD"
                    ? $t("vlink.rule.cloud")
                    : $t("vlink.rule.edge")
                  : "-"
              }}
            </span>
          </el-form-item>
        </el-col>
        <!-- 规则类型 -->
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.rule.ruleType')"
            prop="ruleType"
            :rules="[
              {
                required: currentState === 'add',
                message: $t('validate.required'),
                trigger: 'blur',
              },
            ]"
          >
            <el-select
              v-if="currentState === 'add'"
              v-model="ruleForm.ruleType"
              :style="{ width: '100%' }"
              @change="ruleTypeChange"
              clearable
            >
              <el-option
                value="linkage"
                :label="$t('vlink.rule.linkage')"
              ></el-option>
              <el-option
                value="alarm"
                :label="$t('vlink.rule.alarmRule')"
              ></el-option>
              <el-option
                value="schedule"
                :label="$t('vlink.rule.schedule')"
              ></el-option>
              <el-option
                value="deviceCall"
                :label="$t('vlink.rule.deviceCall')"
              ></el-option>
              <template v-if="ruleForm.ruleScope == 'CLOUD'">
                <el-option value="serverSub" label="数据流转"></el-option>
              </template>
            </el-select>
            <span v-else>
              {{ renderRuleScope(ruleForm.ruleType) }}
            </span>
          </el-form-item>
        </el-col>
        <!-- 规则描述 -->
        <el-col :span="12">
          <el-form-item
            :label="$t('vlink.rule.description')"
            prop="description"
          >
            <el-input
              v-if="currentState === 'add'"
              :placeholder="$t('commons.pleaseInput')"
              v-model="ruleForm.description"
              maxlength="150"
            ></el-input>
            <span v-else>
              <span v-if="descriptionEdit">
                <el-input
                  ref="descInputRef"
                  v-model="ruleForm.description"
                  maxlength="1000"
                  @blur="fieldsUpdateHandler('description')"
                  @keyup.enter.native="(event) => event.target.blur()"
                ></el-input>
              </span>
              <span v-else :title="ruleForm.description">
                {{ ruleForm.description || "-" }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="修改规则描述"
                  placement="top"
                >
                  <i
                    class="el-icon-edit"
                    @click="descEditEnable"
                    v-if="source !== 'dataset'"
                  ></i>
                </el-tooltip>
              </span>
            </span>
          </el-form-item>
        </el-col>
        <template v-if="currentState !== 'add'">
          <!-- 创建时间 -->
          <el-col :span="12">
            <el-form-item label="创建时间" prop="creationDate">
              {{ ruleForm.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
            </el-form-item>
          </el-col>
          <!-- 是否启用 -->
          <el-col :span="12">
            <el-form-item label="是否启用" prop="ruleStatus">
              <el-switch
                :disabled="source === 'dataset'"
                @change="updateRuleStatusHandler(ruleForm.ruleStatus)"
                v-model="ruleForm.ruleStatus"
                active-value="ENABLE"
                inactive-value="DISABLE"
                active-color="#13ce66"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <!-- 规则标识码 -->
          <el-col :span="12">
            <el-form-item label="规则标识码" prop="ruleKey">
              {{ ruleForm.ruleKey || "-" }}
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { updateRuleStatus } from "@/api/ruge/vlink/rule/rule";
import { deepClone } from "@/utils/utils.js";
export default {
  props: {
    currentState: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default() {
        return "url";
      },
    },
    currentRowData: {
      type: Object,
    },
    ruleLoading: {
      type: Boolean,
    },
  },
  watch: {
    currentRowData: {
      handler(row) {
        if (!row) return;
        this.ruleForm.ruleName = row.ruleName;
        this.ruleForm.ruleScope = row.ruleScope;
        this.ruleForm.ruleType = row.ruleType;
        this.ruleForm.description = row.description;
        this.ruleForm.creationDate = row.creationDate;
        this.ruleForm.ruleStatus = row.ruleStatus;
        this.ruleForm.ruleKey = row.ruleKey;
        this.ruleForm.ruleId = row.ruleId;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      ruleForm: {
        ruleId: null,
        ruleName: null,
        ruleScope: "CLOUD",
        ruleType: "linkage",
        description: null,
        creationDate: null,
        ruleStatus: null,
        ruleKey: null,
      },
      rules: {},
      descriptionEdit: false,
      nameEdit: false,
    };
  },
  methods: {
    checkValidAndEmitDatas() {
      return new Promise((resolve) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(this.ruleForm);
          }
        });
      });
    },
    ruleTypeChange(ruleType) {
      this.$emit("ruleTypeChange", ruleType);
    },
    nameEditEnable() {
      this.nameEdit = true;
      this.$nextTick(() => {
        this.$refs.nameInputRef.focus();
      });
    },
    descEditEnable() {
      this.descriptionEdit = true;
      this.$nextTick(() => {
        this.$refs.descInputRef.focus();
      });
    },
    fieldsUpdateHandler(fields) {
      let currentDatas = deepClone(this.currentRowData);
      currentDatas[fields] = this.ruleForm[fields];
      this.$emit("updateField", currentDatas);
      this.nameEdit = false;
      this.descriptionEdit = false;
    },
    renderRuleScope(ruleScope) {
      console.log("ruleScope", ruleScope);
      switch (ruleScope) {
        case "linkage":
          return this.$t("vlink.rule.linkage");
        case "alarm":
          return this.$t("vlink.rule.alarmRule");
        case "schedule":
          return this.$t("vlink.rule.schedule");
        case "deviceCall":
          return this.$t("vlink.rule.deviceCall");
        case "serverSub":
          return this.$t("vlink.rule.dataflow");
        default:
          return "-";
      }
    },
    updateRuleStatusHandler() {
      const { ruleId, ruleStatus, ruleType } = { ...this.ruleForm };
      updateRuleStatus({ ruleId, ruleStatus, ruleType })
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
  },
};
</script>

<style scoped lang="less">
.rule-basic-form {
  .el-icon-edit {
    cursor: pointer;
  }
  ::v-deep .el-form-item__content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>