var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alarm-action-comp" },
    [
      _c(
        "div",
        { staticClass: "detail-line" },
        [
          _c("span", { staticClass: "select-label" }, [_vm._v(" 告警条件 ")]),
          _c("span", { staticClass: "split-span" }, [_vm._v(" 持续时长达到 ")]),
          _c("el-input-number", {
            staticClass: "number-input",
            attrs: {
              disabled: _vm.currentState === "view",
              controls: false,
              min: 0,
            },
            model: {
              value: _vm.form.time,
              callback: function ($$v) {
                _vm.$set(_vm.form, "time", $$v)
              },
              expression: "form.time",
            },
          }),
          _c("span", { staticClass: "split-span" }, [
            _vm._v(" 分钟，并且连续触发达到 "),
          ]),
          _c("el-input-number", {
            staticClass: "number-input",
            attrs: {
              disabled: _vm.currentState === "view",
              controls: false,
              min: 1,
            },
            model: {
              value: _vm.form.times,
              callback: function ($$v) {
                _vm.$set(_vm.form, "times", $$v)
              },
              expression: "form.times",
            },
          }),
          _c("span", { staticClass: "split-span" }, [
            _vm._v(" 次的告警，执行以下设置："),
          ]),
        ],
        1
      ),
      _c(
        "el-form",
        { ref: "actionForm", attrs: { model: _vm.form } },
        [
          _c(
            "el-row",
            { staticClass: "form-line", attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { staticClass: "params-line", attrs: { span: 12 } },
                [
                  _c("div", { staticClass: "params-title" }, [
                    _vm._v("告警状态"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "params-detail" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w330",
                          attrs: { disabled: _vm.currentState === "view" },
                          model: {
                            value: _vm.form.alarmStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "alarmStatus", $$v)
                            },
                            expression: "form.alarmStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.rule.alarm"),
                              value: "alarm",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("vlink.rule.normal"),
                              value: "normal",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.form.alarmStatus === "normal"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("关联告警"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "relatedAlarm",
                                rules: _vm.form.alarmStatus === "normal" && [
                                  {
                                    required: true,
                                    message: "请选择关联告警",
                                    trigger: ["blur", "change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  key: _vm.ruleList.ruleKey,
                                  staticClass: "w330",
                                  attrs: {
                                    disabled: _vm.currentState === "view",
                                    filterable: "",
                                    multiple: "",
                                  },
                                  model: {
                                    value: _vm.form.relatedAlarm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "relatedAlarm", $$v)
                                    },
                                    expression: "form.relatedAlarm",
                                  },
                                },
                                _vm._l(_vm.ruleList, function (item) {
                                  return _c("el-option", {
                                    key: item.ruleKey,
                                    attrs: {
                                      value: item.ruleKey,
                                      label: item.ruleName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "normal"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("恢复内容"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "restoreDetail",
                                rules: _vm.form.alarmStatus === "normal" && [
                                  {
                                    required: true,
                                    message: "请输入恢复内容",
                                    trigger: ["blur", "change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "w330",
                                attrs: {
                                  disabled: _vm.currentState === "view",
                                  placeholder: "请输入恢复内容",
                                },
                                model: {
                                  value: _vm.form.restoreDetail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "restoreDetail", $$v)
                                  },
                                  expression: "form.restoreDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("告警级别"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w330",
                              attrs: { disabled: _vm.currentState === "view" },
                              model: {
                                value: _vm.form.alarmLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "alarmLevel", $$v)
                                },
                                expression: "form.alarmLevel",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("vlink.rule.info"),
                                  value: "info",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("vlink.rule.warn"),
                                  value: "warn",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("vlink.rule.error"),
                                  value: "error",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("告警类型"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "w330",
                              attrs: { disabled: _vm.currentState === "view" },
                              model: {
                                value: _vm.form.alarmType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "alarmType", $$v)
                                },
                                expression: "form.alarmType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("vlink.rule.run"),
                                  value: "run",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("vlink.rule.fault"),
                                  value: "fault",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("告警内容"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "alarmDetail",
                                rules: _vm.form.alarmStatus === "alarm" && [
                                  {
                                    required: true,
                                    message: "请输入告警内容",
                                    trigger: ["blur", "change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "w330",
                                attrs: {
                                  disabled: _vm.currentState === "view",
                                  placeholder: "请输入告警内容",
                                },
                                model: {
                                  value: _vm.form.alarmDetail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "alarmDetail", $$v)
                                  },
                                  expression: "form.alarmDetail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm"
                ? _c(
                    "el-col",
                    {
                      staticClass: "params-line",
                      attrs: { span: _vm.form.repeatedAlarm === "N" ? 24 : 12 },
                    },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("是否重复告警"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "repeatedAlarm" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  disabled: _vm.currentState === "view",
                                  "active-value": "Y",
                                  "inactive-value": "N",
                                  "active-color": "#13ce66",
                                },
                                model: {
                                  value: _vm.form.repeatedAlarm,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "repeatedAlarm", $$v)
                                  },
                                  expression: "form.repeatedAlarm",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm" && _vm.form.repeatedAlarm === "Y"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("最大重复告警次数"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "maxRepeatedCount",
                                rules: _vm.form.repeatedAlarm === "Y" && [
                                  {
                                    required: true,
                                    message: "请输入最大重复告警次数",
                                    trigger: ["blur", "change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "w330",
                                attrs: {
                                  disabled: _vm.currentState === "view",
                                  controls: false,
                                  min: 0,
                                  max: 10,
                                },
                                on: { change: _vm.maxRepeatedCountChange },
                                model: {
                                  value: _vm.form.maxRepeatedCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "maxRepeatedCount", $$v)
                                  },
                                  expression: "form.maxRepeatedCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm"
                ? _c(
                    "el-col",
                    {
                      staticClass: "params-line",
                      attrs: { span: _vm.form.isSendSMS === "N" ? 24 : 12 },
                    },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("是否发送短信"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "isSendSMS" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  disabled: _vm.currentState === "view",
                                  "active-value": "Y",
                                  "inactive-value": "N",
                                  "active-color": "#13ce66",
                                },
                                model: {
                                  value: _vm.form.isSendSMS,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isSendSMS", $$v)
                                  },
                                  expression: "form.isSendSMS",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm" && _vm.form.isSendSMS === "Y"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("发送手机号集合"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "phones",
                                rules: _vm.form.isSendSMS === "Y" && [
                                  {
                                    required: true,
                                    message: "请选择发送手机号集合",
                                    trigger: ["blur", "change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "userSelector",
                                  staticClass: "w330",
                                  attrs: {
                                    disabled: _vm.currentState === "view",
                                    multiple: "",
                                    filterable: "",
                                    remote: "",
                                    "allow-create": "",
                                    "reserve-keyword": "",
                                    placeholder: "请输入关键词",
                                    "remote-method": _vm.remoteMethod,
                                    loading: _vm.loading,
                                  },
                                  on: { change: _vm.userChange },
                                  model: {
                                    value: _vm.form.phones,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "phones", $$v)
                                    },
                                    expression: "form.phones",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: `${item.userName}(${item.phone})`,
                                      value: item.phone,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm"
                ? _c(
                    "el-col",
                    {
                      staticClass: "params-line",
                      attrs: { span: _vm.form.isSendEmail === "N" ? 24 : 12 },
                    },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("是否发送邮件"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "isSendEmail" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  disabled: _vm.currentState === "view",
                                  "active-value": "Y",
                                  "inactive-value": "N",
                                  "active-color": "#13ce66",
                                },
                                model: {
                                  value: _vm.form.isSendEmail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "isSendEmail", $$v)
                                  },
                                  expression: "form.isSendEmail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.form.alarmStatus === "alarm" && _vm.form.isSendEmail === "Y"
                ? _c(
                    "el-col",
                    { staticClass: "params-line", attrs: { span: 12 } },
                    [
                      _c("div", { staticClass: "params-title" }, [
                        _vm._v("发送邮件集合"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "params-detail" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "emails",
                                rules: _vm.form.isSendEmail === "Y" && [
                                  {
                                    required: true,
                                    message: "请选择发送邮件集合",
                                    trigger: ["blur", "change"],
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "emailSelector",
                                  staticClass: "w330",
                                  attrs: {
                                    disabled: _vm.currentState === "view",
                                    multiple: "",
                                    filterable: "",
                                    remote: "",
                                    "allow-create": "",
                                    "reserve-keyword": "",
                                    placeholder: "请输入关键词",
                                    "remote-method": _vm.remoteMethod,
                                    loading: _vm.loading,
                                  },
                                  on: { change: _vm.emailChange },
                                  model: {
                                    value: _vm.form.emails,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "emails", $$v)
                                    },
                                    expression: "form.emails",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: `${item.userName}(${item.email})`,
                                      value: item.email,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }